import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./Team.css";
import { titleCase, getTeam, updateTeam } from "./firebaseData";



function Team(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();
  console.log("Displaying / Editing Team ID: ", params.teamID, team);

  async function fetchData(){
    setUserInfo(await getUser(user));
    const versionInfo = await fetchVersionInfo();
    setVersionID(versionInfo.versionID)
    const team = await getTeam(params.teamID);
    setTeam(team);
    document.title = "Transaction Minder: Team Details: " + team.name;
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    fetchData();
  }, [user, loading]);


  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Account", link: ""}, {title: "Teams", link: '/teams'}, {title: team.name, link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="teams" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !team && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { team && 
                <>
                <div className="card-header border-bottom clearfix">
                  <h1 className="h2 mb-0 clearfix">Team Details
                  {userInfo.uid === team.owner &&
                  <a className="btn btn-primary float-end" href={"/team/edit/" + team.id}><i className="bi bi-pencil-fill me-2"></i>Edit Team Info</a>
                  }
                  </h1>
                </div>

                <div className="card-body">
                  <div className="row text-black">
                  { team.name &&
                      <><div className="col-3 fw-bold">Team Name:</div><div className="col-9">{team.name}</div></>
                  }
                  { team.companyName &&
                      <><div className="col-3 fw-bold">Company Name:</div><div className="col-9">{team.companyName}</div></>
                  }
                  { team.brokerLicense &&
                      <><div className="col-3 fw-bold">Broker License:</div><div className="col-9">{team.brokerLicense}</div></>
                  }
                  { team.type &&
                      <><div className="col-3 fw-bold">Team Type:</div><div className="col-9">{titleCase(team.type)}</div></>
                  }
                  { team.website &&
                      <><div className="col-3 fw-bold">Website:</div><div className="col-9">{team.website}</div></>
                  }
                  { team.officeAddress &&
                      <><div className="col-3 fw-bold">Office Address:</div><div className="col-9">{team.officeAddress}</div></>
                  }
                  { team.ownerUser &&
                      <><div className="col-3 fw-bold">Team Owner:</div><div className="col-9">{team.ownerUser.name} (<a href={"mailto:" + team.ownerUser.email}>{team.ownerUser.email}</a>)</div></>
                  }
                  </div>

                  {team.acceptedUsers && team.acceptedUsers.length > 0 &&
                    <div className="mt-5">
                      <h4>Team Members</h4>
                      <ol>
                        {team.acceptedUsers.map(theUser =>
                        <li key={theUser.uid}>{theUser.name} (<a href={"mailto:" + theUser.email}>{theUser.email}</a>)</li>
                        )}
                      </ol>
                    </div>
                  }

                  {team.invitedUsers && team.invitedUsers.length > 0 &&
                    <div className="mt-5">
                      <h4>Invited Members</h4>
                      <ol>
                        {team.invitedUsers.map(theUser =>
                        <li key={theUser.uid}>{theUser.name} (<a href={"mailto:" + theUser.email}>{theUser.email}</a>)</li>
                        )}
                      </ol>
                    </div>
                  }
                  
                  { team.logo && 
                     <div className="mt-5">
                      <h4>Team Logo</h4>
                      <div id="imageDisplayArea" className="mb-3 p-5 text-center border rounded">
                          <img style={{ maxHeight: 200, maxWidth: 200 }} src={team.logo}  />
                      </div>
                    </div>
                  }


                </div>
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Team;