import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import DatePicker from "react-datepicker"; // From https://reactdatepicker.com/
import MaskedInput from "react-text-mask";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./TransactionAdd.css";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, addBusinessDays, subDays, subBusinessDays } from "date-fns";
import {  Timestamp } from "firebase/firestore";
import { getTransactionTemplate, addTransaction, titleCase, currencyMask, dateMask, dateMaskPipe } from "./firebaseData";



var compareDisplayIfValues = {
  '===': function (x, y) { return x === y },
  '>': function (x, y) { 
    let xNum = parseInt(x.replace(/[^0-9\.-]+/g,""));
    return xNum > y 
  } 
}

function TransactionAdd(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [dates, setDates] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  console.log("Adding Transaction with Template ID: ", params.templateID);


  async function fetchData(){
    console.log("Fetching Data...");
    const userDoc = await getUser(user);
    const versionInfo = await fetchVersionInfo();
    const transactionTemplate = await getTransactionTemplate(params.templateID);
    setUserInfo(userDoc);
    setVersionID(versionInfo);
    setSelectedTemplate(transactionTemplate);

    let templateDates = [];
    transactionTemplate.fields.forEach((field) => {
      if(field.type === 'date'){
        templateDates[field.name] = new Date();
      }
    });
    setDates(templateDates);

  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    fetchData();
  }, [user, loading]);

  if(selectedTemplate && selectedTemplate.id !== params.templateID){
    fetchData();
  }


  const validateForm = ((formJson, transactionTemplate) =>{
    let isValid = true;
    let transaction = { templateID: transactionTemplate.id, reminderType: transactionTemplate.reminderType };

    if(userInfo.companyLogo){
      transaction["companyLogo"] = userInfo.companyLogo;
    }

    console.log(formJson);
    console.log(transactionTemplate);

    let acceptDate = null;
    let closeDate = null;

    console.log('Checking Fields...');
    transactionTemplate.fields.forEach((field) =>{
      let formItem = formJson[field.name];
      console.log('Field Name: ', field.name, ' Required: ', field.required, ' Type: ', field.type, ' Value: ', formItem);

      var formElement = document.getElementById(field.name);  

      if(!formItem){
        if(field.required){
          isValid = false;
          formElement.classList.remove('is-valid');
          formElement.classList.add('is-invalid');
          console.log('Missing required value...');
        }
      } else {
        //Continue validation...
        if(field.type === 'text'){
          formElement.classList.remove('is-invalid');
          formElement.classList.add('is-valid');
          //console.log('Required text value found...');
          transaction[field.name] = formItem;
        }

        if(field.type === 'choice'){
          formElement.classList.remove('is-invalid');
          formElement.classList.add('is-valid');
          //console.log('Required text value found...');
          transaction[field.name] = formItem;
        }


        if(field.type === 'date'){
          let valueAsDate = new Date(formItem);
          if(isNaN(valueAsDate)){
            isValid = false;
            formElement.classList.remove('is-valid');
            formElement.classList.add('is-invalid');
            console.log('Date value is not a valid date...');
          } else {
            formElement.classList.remove('is-invalid');
            formElement.classList.add('is-valid');
            //console.log('Required date value found...');
            //Store date...
            transaction[field.name] = valueAsDate;
            if(field.name === 'accepted'){
              acceptDate = valueAsDate;
              transaction['acceptDate'] = Timestamp.fromDate(valueAsDate);
            }
            if(field.name === 'coe'){
              closeDate = valueAsDate;
              transaction['closeDate'] = Timestamp.fromDate(valueAsDate);
            }
          }
        }

        if(field.type === 'number'){
          let valueAsNumber = parseInt(formItem.replace(/[^0-9\.-]+/g,""));
          if(isNaN(valueAsNumber)){
            isValid = false;
            formElement.classList.remove('is-valid');
            formElement.classList.add('is-invalid');
            console.log('Number value is not a valid number...');
          } else {
            formElement.classList.remove('is-invalid');
            formElement.classList.add('is-valid');
            //console.log('Required number value found...');
            //Store number...
            transaction[field.name] = valueAsNumber;
          }
        }
      }
    });

    let reminders = [];

    console.log('Checking Reminders...');
    transactionTemplate.reminders.forEach((field) =>{

      let includeReminder = true;
      let displayIf = field.displayIf;
      if(displayIf){
        console.log(displayIf);
        console.log(formJson[displayIf[0]]);
        
        if (compareDisplayIfValues[displayIf[1]](formJson[displayIf[0]], displayIf[2])){
          //Include
          includeReminder = true;
        } else {
          //Skip...
          includeReminder = false;
        }
      }

      let reminder = {};

      if(transactionTemplate.reminderType === 'milestone completion'){
        reminder["completed"] = false;
        reminder["title"] = field.label;
        reminder["helperText"] = field.helperText;
        reminder["order"] = field.order;  
      } else {


        let formItem = formJson[field.name];
        console.log('Reminder Name: ', field.name, ' Required: ', field.required, ' Type: ', field.type, ' Value: ', formItem);

        var formElement = document.getElementById(field.name);  

        if(!formItem){
          if(field.required){
            isValid = false;
            formElement.classList.remove('is-valid');
            formElement.classList.add('is-invalid');
            console.log('Missing required value...');
          }
        } else {
          //Continue validation...
          reminder["completed"] = false;
          reminder["title"] = field.label;

          let formItemAsNumber = parseInt(formItem);
          if(field.type === 'calendar days from accepted date'){
              //Calculate and store date...
              reminder["dueDate"] = Timestamp.fromDate(addDays(acceptDate, formItemAsNumber));
              reminder["helperText"] = formItemAsNumber + " " + field.type;
          }

          if(field.type === 'business days from accepted date'){
              //Calculate and store date...
              reminder["dueDate"] = Timestamp.fromDate(addBusinessDays(acceptDate, formItemAsNumber));
              reminder["helperText"] = formItemAsNumber + " " + field.type;
          }

          if(field.type === 'calendar days before close of escrow date'){
              //Calculate and store date...
              reminder["dueDate"] = Timestamp.fromDate(subDays(closeDate, formItemAsNumber));
              reminder["helperText"] = formItemAsNumber + " " + field.type;
            }

          if(field.type === 'business days before close of escrow date'){
              //Calculate and store date...
              reminder["dueDate"] = Timestamp.fromDate(subBusinessDays(closeDate, formItemAsNumber));
              reminder["helperText"] = formItemAsNumber + " " + field.type;
          }

          if(field.type === 'date'){
            let valueAsDate = new Date(formItem);
            if(isNaN(valueAsDate)){
              isValid = false;
              formElement.classList.remove('is-valid');
              formElement.classList.add('is-invalid');
              console.log('Date value is not a valid date...');
            } else {
              formElement.classList.remove('is-invalid');
              formElement.classList.add('is-valid');
              console.log('Required date value found...');
              //Store date...
              reminder["dueDate"] = Timestamp.fromDate(valueAsDate);
              reminder["helperText"] = "on date specified";
            }
          }
        }
      }
      if(includeReminder){
        reminders.push(reminder);
      }
    });

    transaction['reminders'] = reminders;

    if(isValid){
      //return the processed data for storage...
      console.log(transaction);
      return(transaction);
    }
    console.log('Invalid form data...');
    return(false);
  });

  const handleChange = (async (event) => {

    const form = document.getElementById("addTransactionForm");
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log('VALUE Changed...');
    console.log(formJson);

    //let visibilityChanged = false;
    let template = selectedTemplate;
    let reminders = template.reminders;
    for(let i = 0; i < reminders.length; i++){
      let displayIf = reminders[i].displayIf;
      if(displayIf){
        console.log("Here! ", reminders[i].name,  displayIf);
        if (compareDisplayIfValues[displayIf[1]](formJson[displayIf[0]], displayIf[2])){
          //console.log(document.getElementById(reminders[i].name));
          if(document.getElementById(reminders[i].name).type != 'hidden'){
            document.getElementById(reminders[i].name).parentElement.parentElement.classList.remove('d-none')
          }
        } else {
          //console.log(document.getElementById(reminders[i].name));
          if(document.getElementById(reminders[i].name).type != 'hidden'){
            document.getElementById(reminders[i].name).parentElement.parentElement.classList.add('d-none')
          }
        }
      }
    }

  });

  const handleSubmit = (async (event) =>{
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    let transaction = validateForm(formJson, selectedTemplate);
    if(transaction){
      console.log("Saving transaction: ", transaction)
      await addTransaction(user, userInfo.name, transaction);
      return navigate("/dashboard");
    } else {
      console.log('Errors!');
    }

  }); 

  const handleDateChange = (date, fieldName) => {
    //console.log("Handle Date Change: ", fieldName, date);
    //console.log("Original:" , dates);
    let newDates = structuredClone(dates);
    //console.log("Clone:" , newDates);
    newDates[fieldName] = date;
    //console.log("Updated: ", newDates);
    setDates(newDates);
  }


  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Transactions", link: "/"}, {title: "Add Transaction", link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="transactions" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !selectedTemplate && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { selectedTemplate && 
                <>
                <div className="card-header border-bottom">
                <h1>Add Transaction</h1>
                <h2 className="h6 text-muted">
                  Please enter the relevant contractual terms for this transaction.<br />
                  <span className="templateVersionInfo">(Template: { selectedTemplate.id } - { selectedTemplate.title })</span>
                </h2>
                </div>

                <div className="card-body">
                  <form id="addTransactionForm" method="post" onSubmit={handleSubmit} className="needs-validation" noValidate>
          
                    <div className="row row-cols-2">

                    { selectedTemplate.fields.map(field => (            
                      
                      <div className="col" key={field.name}>
                        <div className="mb-3">
                          <label htmlFor={field.name} className="form-label">{field.label}</label>


                          { field.type === 'choice' && field.choices &&
                            <select id={field.name} name={field.name} className="form-select" onChange={handleChange} > 
                              {field.choices.map(choice => (
                                <option key={choice} value={choice}>{titleCase(choice)}</option>
                              ))}
                            </select>
                          }

                          { field.type === 'text' &&
                            <input type="text" id={field.name} name={field.name} className="form-control" placeholder={field.helperText} aria-label={field.helperText} required={field.required} onChange={handleChange} />
                          }

                          { field.type === 'date' && 
                          <DatePicker className="form-control" selected={dates[field.name]} id={field.name} name={field.name} required={field.required} todayButton="Today" showPopperArrow={false} onChange={(date) => handleDateChange(date, field.name)} />
                          }

                          { field.type === 'number' && 
                          <MaskedInput mask={currencyMask} className="form-control" id={field.name} name={field.name} placeholder={field.helperText} required={field.required} onChange={handleChange} />  
                          }

                          <span className="form-text"><sup className="text-danger">{field.required ? '*': ''}</sup> {field.required ? 'Required.' : ''} {field.helperText}.</span>
                        </div>
                      </div>
                    
                    ))}
                      

                      { selectedTemplate.reminders.map(field => (            
                      field.editable && 
                      <div className={field.hidden ? "d-none" : "col"} key={field.name}>
                        <div className="mb-3">
                          <label htmlFor={field.name} className="form-label">{field.label}</label>

                          <input type="number" id={field.name} name={field.name} className="form-control" placeholder={titleCase(field.type)} aria-label={field.helperText} required={field.required}/>

                          <span className="form-text"><sup className="text-danger">{field.required ? '*': ''}</sup> {field.required ? 'Required.' : ''} {field.helperText}.</span>
                        </div>
                      </div>
                    ))}

                    { selectedTemplate.reminders.map(field => (            
                    field.editable === false && 
                        <input key={field.name} type="hidden" id={field.name} name={field.name} defaultValue={field.value}/>
                    ))}                   

                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <a href="/dashboard" className="btn btn-secondary">Cancel</a>
                      <button className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default TransactionAdd;