import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, updateUser } from "./firebaseAuth";
import MaskedInput from "react-text-mask";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./Team.css";
import { addDays, addBusinessDays, subDays, subBusinessDays } from "date-fns";
import {  Timestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getTransactionTemplates, getTeam, updateTeam, getUserByID } from "./firebaseData";
import { FileUploader } from "react-drag-drop-files";



function TeamEdit(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [transactionTemplates, setTransactionTemplates] = useState('');
  const [team, setTeam] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [uploadFile, setUploadFile] = useState('');

  const [teamName, setTeamName] = useState('');
  const [teamType, setTeamType] = useState('');
  const [teamBrokerLicense, setTeamBrokerLicense] = useState('');
  const [teamCompanyName, setTeamCompanyName] = useState('');
  const [teamAccepted, setTeamAccepted] = useState([]);
  const [teamAcceptedUsers, setTeamAcceptedUsers] = useState([]);
  const [teamInvited, setTeamInvited] = useState([]);
  const [teamInvitedUsers, setTeamInvitedUsers] = useState([]);
  const [teamLogo, setTeamLogo] = useState('');
  const [teamOfficeAddress, setTeamOfficeAddress] = useState('');
  const [teamWebsite, setTeamWebsite] = useState('');
  
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();
  console.log("Displaying / Editing Team ID: ", params.teamID, team);

  async function fetchData(){
    const userDoc = await getUser(user);
    const versionInfo = await fetchVersionInfo();
    const transactionTemplates = await getTransactionTemplates();
    const team = await getTeam(params.teamID);
    setTeamName(team.name);
    setTeamType(team.type);
    setTeamBrokerLicense(team.brokerLicense);
    setTeamCompanyName(team.companyName);
    setTeamAccepted(team.accepted);
    setTeamAcceptedUsers(team.acceptedUsers);
    setTeamInvited(team.invited);
    setTeamInvitedUsers(team.invitedUsers);
    setTeamLogo(team.logo);
    setTeamOfficeAddress(team.officeAddress);
    setTeamWebsite(team.website);
    setUserInfo(userDoc);
    setVersionID(versionInfo);
    setTransactionTemplates(transactionTemplates);
    setTeam(team);
    document.title = "Transaction Minder: Team Details: " + team.name;
    //console.log(team);
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    fetchData();
  }, [user, loading]);


  const handleSave = (async (event) =>{
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    if(uploadFile){
      await handleUploadFile();
    }

    /*
    let acceptedUsers = [];
    let invitedUsers = [];

    for(let i=0; i < teamAccepted.length; i++) {
      let theUser = await getUserByID(teamAccepted[i])
      acceptedUsers.push(theUser);
    }

    for(let i=0; i < teamInvited.length; i++) {
      let theUser = await getUserByID(teamInvited[i])
      invitedUsers.push(theUser);
    }
    */

    //let teamOwner = await getUserByID(team.owner);

    let updatedTeam = {
      id: team.id,
      name: teamName,
      companyName: teamCompanyName,
      brokerLicense: teamBrokerLicense,
      officeAddress: teamOfficeAddress,
      type: teamType,
      accepted: teamAccepted,
      acceptedUsers: teamAcceptedUsers,
      invited: teamInvited,
      invitedUsers: teamInvitedUsers,
      logo: teamLogo,
      website: teamWebsite,
      owner: team.owner,
      ownerUser: teamOwner
    }

    setShowAlert(true);
    await updateTeam(updatedTeam);
    fetchData();

    // Display update message...
    setUploadFile('');
    let timeout = setTimeout(() => {
      setShowAlert(false);
    }, 5000);

  });


  const handleUploadFile = async (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      let extension = uploadFile.name.split('.').pop();
  
      const storageRef = ref(storage, 'branding/user/' + userInfo.uid + '/companyLogo.' + extension);
      const uploadTask = uploadBytesResumable(storageRef, uploadFile);

      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');

          var progressBar = document.getElementById('progress-company-logo').querySelector('.progress-bar');
          if(progressBar){
            progressBar.style.width = progress + '%';
            progressBar.setAttribute('aria-valuenow', progress);
          }

          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.error('Error uploading file...', error);
          reject(error);
        }, 
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL, 'for User ID: ', userInfo.uid);
            console.log('User: ', userInfo);
            userInfo.companyLogo = downloadURL;
            //firestore.collection('changeSamuiCustomers').doc(customerID).update(customer);
            //console.log('Customer updated...');
            resolve();
          });
        }
      );        
    });
  }

  const handleImageDrop = (file) => {
    console.log('Handle Image Drop: ', file);
    let extension = file.name.split('.').pop();
    console.log(extension);
    setUploadFile(file);
  }

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Account", link: ""}, {title: "Teams", link: '/teams'}, {title: team.name, link: '/team/' + team.id}, {title: "Edit", link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="teams" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !team && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { transactionTemplates && 
                <>
                <div className="card-header border-bottom">
                <h1 className="h2 mb-0">Team Details</h1>
                <h6 className="mt=0 text-muted fw-normal">Please update as needed.</h6>
                </div>

                <div className="card-body">
                  <form id="editTeamForm" method="post" onSubmit={handleSave} className="needs-validation" noValidate>
                    <div className="form-floating mb-2">
                      <input type="text" className="form-control" name="name" id="name" value={teamName} onChange={(e) => setTeamName(e.target.value)} />
                      <label htmlFor="name" className="form-label">Team Name</label>
                    </div>

                    <div className="form-floating mb-2">
                      <input type="text" className="form-control" name="companyName" id="companyName" value={teamCompanyName} onChange={(e) => setTeamCompanyName(e.target.value)} />
                      <label htmlFor="companyName" className="form-label">Company Name</label>
                    </div>

                    <div className="form-floating mb-2">
                      <input type="text" className="form-control" name="brokerLicense" id="brokerLicense" value={teamBrokerLicense} onChange={(e) => setTeamBrokerLicense(e.target.value)} />
                      <label htmlFor="brokerLicense" className="form-label">Broker License</label>
                    </div>

                    <div className="form-floating mb-2">
                      <select id="type" name="type" className="form-select" aria-label="Team Type" value={teamType}  onChange={(e) => setTeamType(e.target.value)}>
                        <option value="company">Company</option>
                        <option value="office">Office</option>
                        <option value="team">Team</option>
                      </select>
                      <label htmlFor="type" className="form-label">Type</label>
                    </div>

                    <div className="form-floating mb-2">
                      <input type="text" className="form-control" name="website" id="website" value={teamWebsite} onChange={(e) => setTeamWebsite(e.target.value)} />
                      <label htmlFor="website" className="form-label">Website</label>
                    </div>

                    <div className="form-floating mb-2">
                      <textarea rows="3" className="form-control" name="officeAddress" id="officeAddress" value={teamOfficeAddress} onChange={(e) => setTeamOfficeAddress(e.target.value)} />
                      <label htmlFor="officeAddress" className="form-label">Office Address</label>
                    </div>

                    <div className="form-floating mb-2">
                      <select id="defaultTemplate" name="defaultTemplate" className="form-select" aria-label="Default Template" defaultValue={userInfo.defaultTemplate}>
                        { transactionTemplates.map(template => ( 
                        <option key={template.id} value={template.id}>{template.title}</option>
                        ))}
                      </select>
                      <label htmlFor="defaultTemplate" className="form-label">Default Template</label>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="companyLogo" className="form-label fw-bold">Company Logo</label>

                      { userInfo.companyLogo && 
                      <div id="imageDisplayArea" className="mb-3 border rounded p-3">
                          <img style={{ maxHeight: 200, maxWidth: 200 }} src={userInfo.companyLogo}  />
                      </div>
                      }

                      {uploadFile &&
                      <div id="imagePreviewArea" className="my-3 border rounded p-3">
                          <img style={{ maxHeight: 200, maxWidth: 200 }} src={URL.createObjectURL(uploadFile)} />
                          <div id='progress-company-logo' className="progress my-1" style={{ minHeight: '20px'}}>
                              <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width: '0%'}}></div>
                          </div>
                      </div>
                      }

                      <FileUploader handleChange={handleImageDrop} name="file" types={["JPG", "JPEG", "PNG"]} multiple={false} />
                    </div>  
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button className="btn btn-primary"><i className="bi bi-save me-2"></i>Save</button>
                    </div>
                  </form>
                </div>
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Account preferences updated...</p>                    
                </div>
            </div>
          </div>
      )}
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default TeamEdit;