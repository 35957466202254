import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./Teams.css";
import { getTransactionTemplates, addTransactionTemplate } from "./firebaseData";

function Templates(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [templates, setTemplates] = useState('');
  const [versionID, setVersionID] = useState('');

  const [templateName, setTemplateName] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateReminderType, setTemplateReminderType] = useState('daily details');

  const navigate = useNavigate();
  const addTemplateModalRef = useRef();

  async function fetchData(){
    const userDoc = await getUser(user);
    const versionInfo = await fetchVersionInfo();
    setUserInfo(userDoc);
    setVersionID(versionInfo);
    setTemplates(await getTransactionTemplates());
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    document.title ="Transaction Minder: Templates";

    fetchData();
  }, [user, loading]);

  const handleShowAddTemplate = () => {
    const addTemplateModal = addTemplateModalRef.current;
    const bsModal = new bootstrap.Modal(addTemplateModal);
    bsModal.show();      
  }

  const handleHideAddTemplateModal = () => {
    const addTemplateModal = addTemplateModalRef.current;
    const bsModal = bootstrap.Modal.getInstance(addTemplateModal);
    bsModal.hide();    

    [...document.getElementsByClassName("modal-backdrop")].forEach((element) => {
      element.remove();
    });

  }



  const handleSaveNewTemplate = async() => {
    console.log("Handle Save New Template...");
    if(templateName && templateTitle){
      console.log("Creating shell template and continuing...");
      let newTemplate = {
        name: templateName,
        title: templateTitle,
        reminderType: templateReminderType,
        owner: userInfo.uid,
        order: 100,
        published: false,
        fields: [],
        reminders: []
      }
      let docRef = await addTransactionTemplate(newTemplate);
      handleHideAddTemplateModal();
      return navigate("/template/add/" + docRef.id);

    } else {
      alert("You must enter the template name and title before continuing...");
    }
  }

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Transactions", link: ''}, {title: "Templates", link: '/templates'}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="templates" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !templates && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { templates && templates.length > 0 && 
                <>
                <div className="card-header border-bottom">
                  <h1 className="h2 clearfix">
                    <i className="bi bi-files nav-icon me-2"></i>Templates
                    { userInfo.roles && userInfo.roles.includes('superadmin') &&
                    <button className="btn btn-success float-end" onClick={handleShowAddTemplate}><i className="bi bi-plus-circle me-2" /> Add Template</button>
                    }
                  </h1>                
                </div>

                <div className="card-body">
                  <h4 className="mb-3">Published Templates</h4>
                  {templates.map(theTemplate => (
                    theTemplate.published === true &&
                    <div key={theTemplate.id}>
                      <p><a href={"/template/" + theTemplate.id}><i className="bi bi-files nav-icon me-2"></i><strong>{theTemplate.name}</strong> ({theTemplate.title})</a></p>
                    </div>
                  ))}                  

                  <h4 className="mb-3 mt-5">My Unpublished Templates</h4>
                  {templates.map(theTemplate => (
                    (theTemplate.published === false && theTemplate.owner === userInfo.uid) &&
                    <div key={theTemplate.id}>
                      <p><a href={"/template/" + theTemplate.id}><i className="bi bi-files nav-icon me-2"></i><strong>{theTemplate.name}</strong> ({theTemplate.title})</a></p>
                    </div>
                  ))}                  

                </div>

                
                </>
              }                
              </div>
            </div> 
          </div>
        </div>


        <div className="modal fade" id="addTemplateModal" tabIndex="-1" aria-labelledby="addTemplateDialogLabel" aria-hidden="true" ref={addTemplateModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addTemplateDialogLabel">Add Template</h2>
              <button type="button" className="btn-close"  onClick={handleHideAddTemplateModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="templateName" id="templateName" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                <label htmlFor="templateName" className="form-label">Template Name</label>
              </div>

              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="templateTitle" id="templateTitle" value={templateTitle} onChange={(e) => setTemplateTitle(e.target.value)} />
                <label htmlFor="templateTitle" className="form-label">Template Title</label>
              </div>

              <div className="form-floating mb-2">
                <select id="templateReminderType" name="templateReminderType" className="form-select" aria-label="Team Type" value={templateReminderType}  onChange={(e) => setTemplateReminderType(e.target.value)}>
                  <option value="daily details">Daily Details</option>
                  <option value="milestone completion">Milestone Completion</option>
                </select>
                <label htmlFor="templateReminderType" className="form-label">Reminder Type</label>
              </div>         

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleHideAddTemplateModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveNewTemplate}><i className="bi bi-save me-1"></i>Save and Continue...</button>
            </div>
          </div>
        </div>
      
      </div>


      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Templates;