import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, signInWithGoogle } from "./firebaseAuth";
import "./Register.css";
import logo from "./logo.800x200.png";
import { isValid } from "date-fns";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useNavigate();
  const register = () => {
    let isValid = true;
    if (!name) {
      alert("Please enter your full name");
      isValid = false;
    } else {
      if (!email) {
        alert("Please enter your email address");
        isValid = false;
      } else {
        if (!password) {
          alert("Please enter your email address");
          isValid = false;
        }  
      }
    }
    if(isValid){
      registerWithEmailAndPassword(name, email, password);
    }
  };
  useEffect(() => {
    if (loading) return;
    if (user) history("/dashboard");
  }, [user, loading]);


  return (

    <div className="mt-10">
      <div className="container">
        <header className="text-center mb-5">
          <a href="/"><img className="img-fluid" style={{ width: '400px' }} src={logo} alt="Transaction Minder" /></a>
        </header>

        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full Name"
                  />
                </div>
                <div className="mb-3">

                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
                <div className="mb-3 text-center">
                  <button className="btn btn-dark mb-2" onClick={register}>Register</button>
                  <button className="btn btn-primary mb-2 ms-2" onClick={signInWithGoogle}><i className="bi bi-google me-2"></i>Register with Google</button>
                </div>
                <div>
                  Already have an account? <Link to="/login">Login</Link> now.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;