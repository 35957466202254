import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import { titleCase, getTransactionTemplate } from "./firebaseData";



function Template(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [template, setTemplate] = useState('');
  
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();
  console.log("Displaying / Editing Template ID: ", params.templateID, template);

  async function fetchData(){
    setUserInfo(await getUser(user));
    const versionInfo = await fetchVersionInfo();
    setVersionID(versionInfo.versionID)
    const template = await getTransactionTemplate(params.templateID);
    setTemplate(template);
    document.title = "Transaction Minder: Template Details: " + template.title;
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    fetchData();
  }, [user, loading]);


  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Transactions", link: ""}, {title: "Templates", link: '/templates'}, {title: template.id, link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="templates" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !template && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { template && 
                <>
                <div className="card-header border-bottom clearfix">
                  <h1 className="h2 mb-0 clearfix"><i className="bi bi-files nav-icon me-2"></i>Template Details
                  {userInfo.uid === template.owner &&
                  <a className="btn btn-primary float-end" href={"/template/edit/" + template.id}><i className="bi bi-pencil-fill me-2"></i>Edit Template</a>
                  }
                  </h1>
                </div>

                <div className="card-body">
                  <div className="row text-black">
                  { template.id &&
                      <><div className="col-3 fw-bold">ID:</div><div className="col-9">{template.id}</div></>
                  }
                  { template.name &&
                      <><div className="col-3 fw-bold">Name:</div><div className="col-9">{template.name}</div></>
                  }
                  { template.title &&
                      <><div className="col-3 fw-bold">Title:</div><div className="col-9">{template.title}</div></>
                  }
                  { template.reminderType &&
                      <><div className="col-3 fw-bold">Reminder Type:</div><div className="col-9">{titleCase(template.reminderType)}</div></>
                  }
                  </div>

                  {template.fields && template.fields.length > 0 &&
                    <div className="mt-5 d-xs-block d-sm-block d-md-none">
                      <h4>Fields</h4>
                      <ol className="list-group list-group-numbered">
                        {template.fields.map(theField =>
                        <li className="list-group-item d-flex justify-content-between align-items-start" key={theField.name}>
                          <div className="ms-2 me-auto">
                          <strong>Label</strong>: {theField.label}<br />
                          <strong>Name</strong>: {theField.name}<br />
                          <strong>Required</strong>: {theField.required ? <i className="ms-2 bi bi-check-circle-fill"></i> : <i className="ms-2 bi bi-circle"></i>}<br />
                          <strong>Type</strong>: {titleCase(theField.type)}
                          </div>
                        </li>
                        )}
                      </ol>
                    </div>
                  }

                  {template.fields && template.fields.length > 0 &&
                    <div className="mt-5 d-none d-md-block">
                      <h4>Fields</h4>
                      <table className="table table-striped table-hover small table-bordered table-sm table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Label</th>
                            <th scope="col">Name</th>
                            <th className="text-center" scope="col">Required</th>
                            <th scope="col">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                        {template.fields.map(theField =>
                        <tr key={theField.name}>
                          <td>{theField.label}</td>
                          <td>{theField.name}</td>
                          <td className="text-center">{theField.required ? <i className="ms-2 bi bi-check-circle-fill"></i> : <i className="ms-2 bi bi-circle"></i>}</td>
                          { theField.choices && theField.choices.length > 0 
                          ? <td>Choice</td>
                          : <td>{titleCase(theField.type)}</td>
                          }
                        </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                  }


                  {template.reminders && template.reminders.length > 0 &&
                    <div className="mt-5 d-xs-block d-sm-block d-md-none">
                      <h4>Reminders</h4>
                      <ol className="list-group list-group-numbered">
                        {template.reminders.map(theReminder =>
                        <li className="list-group-item d-flex justify-content-between align-items-start" key={theReminder.name}>
                          <div className="ms-2 me-auto">
                          <strong>Label</strong>: {theReminder.label}<br />
                          <strong>Name</strong>: {theReminder.name}<br />
                          <strong>Required</strong>: {theReminder.required ? <i className="ms-2 bi bi-check-circle-fill"></i> : <i className="ms-2 bi bi-circle"></i>}<br />
                          <strong>Editable</strong>: {theReminder.editable ? <i className="ms-2 bi bi-check-circle-fill"></i> : <i className="ms-2 bi bi-circle"></i>}<br />
                          <strong>Type</strong>: {titleCase(theReminder.type)}<br />
                          <strong>Value</strong>: {theReminder.value}
                          </div>
                        </li>
                        )}
                      </ol>
                    </div>
                  }    

    


                  {template.reminders && template.reminders.length > 0 &&
                    <div className="mt-5 d-none d-md-block">
                      <h4>Reminders</h4>
                      <table className="table table-striped table-hover small table-bordered table-sm table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Label</th>
                            <th scope="col">Name</th>
                            <th className="text-center" scope="col">Required</th>
                            <th className="text-center" scope="col">Editable</th>
                            <th scope="col">Type</th>
                            <th scope="col">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                        {template.reminders.map(theReminder =>
                        <tr key={theReminder.name}>
                          <td>{theReminder.label}</td>
                          <td>{theReminder.name}</td>
                          <td className="text-center">{theReminder.required ? <i className="ms-2 bi bi-check-circle-fill"></i> : <i className="ms-2 bi bi-circle"></i>}</td>
                          <td className="text-center">{theReminder.editable ? <i className="ms-2 bi bi-check-circle-fill"></i> : <i className="ms-2 bi bi-circle"></i>}</td>
                          <td>{titleCase(theReminder.type)}</td>
                          <td>{theReminder.value}</td>
                        </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                  }                


                </div>
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Template;