import React from "react";
import { validateEmail } from "./firebaseData";
import "./TransactionContacts.css";

function TransactionContacts(props) {

  const handleSaveTransactionContacts = (async (event) => { 
    event.preventDefault();

    console.log('Transaction Contacts - Save Event');

    const form = document.getElementById('editTransactionContactsForm');
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    //Validate that value is a CSV of email addresses...
    let isValid = true;
    let formElement = document.getElementById("editTransactionContactList");  
    if(formJson.transactionContacts){
      let emailList = formJson.transactionContacts.split(',').map(function(item) {
        return item.trim();
      });
      console.log(emailList);
  
      if(emailList[0] === ''){
        emailList = [];
      } else {
  
        for(let i=0; i < emailList.length; i++) {
          let emailAddress = emailList[i];
          console.log("Testing: ", emailAddress);
          if(!validateEmail(emailAddress)){
            isValid = false;
            break;
          }
        };  
  
      }  
    } else {
      isValid = false;
    }

    if(isValid){
      //Manually hide modal...
      document.getElementById('modalTransactionContacts').classList.remove('show');
      document.body.classList.remove('modal-open');
      document.getElementsByClassName('modal-backdrop')[0].remove();
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');

      formElement.value = "";
      formElement.classList.remove('is-invalid');
      formElement.classList.remove('is-valid');
      document.getElementById("isValid").classList.remove('text-danger');
      document.getElementById("isValid").classList.remove('fw-bold');

      let results = {
        contactsTransactionID: formJson.contactsTransactionID,
        emailList: emailList
      }

      props.callback(results);
    } else {
      formElement.classList.remove('is-valid');
      formElement.classList.add('is-invalid');
      document.getElementById("isValid").classList.add('text-danger');
      document.getElementById("isValid").classList.add('fw-bold');
      console.log('List does not contain valid email addresses...');
    }

  });


  return (
    <>
      <div className="modal fade" id="modalTransactionContacts" tabIndex="-1" aria-labelledby="modalTransactionContactsLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form id="editTransactionContactsForm" method="post" onSubmit={handleSaveTransactionContacts} className="needs-validation" noValidate>
              <div className="modal-header">
                <h3 className="modal-title" id="modalTransactionContactsLabel"><i className="bi bi-person-rolodex  me-2"></i>Transaction Contacts</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
              <input type="hidden" id="contactsTransactionID" name="contactsTransactionID" />
                <div className="mb-3">
                  <label htmlFor="editTransactionContactList" className="form-label">Transaction Contact List</label>
                  <input type="text" id="editTransactionContactList" name="transactionContacts" className="form-control" placeholder="Comma separated list of email addresses" aria-label="Transaction Contact List" />
                  <span className="form-text">Enter a comma separated list of <span id="isValid">valid</span> email addresses for people you want to receive copies of update emails.</span>
                </div>
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveTransactionContacts} >Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>    
    </>
  );
}

export default TransactionContacts;
