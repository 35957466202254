import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import MaskedInput from "react-text-mask";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./Teams.css";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getTeams, updateTeam } from "./firebaseData";
import { FileUploader } from "react-drag-drop-files";
import { applyActionCode } from "firebase/auth";



function Teams(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [teams, setTeams] = useState('');
  const [myOwnedTeams, setMyOwnedTeams] = useState('');
  const [myTeamInvites, setMyTeamInvites] = useState('');
  const [myAcceptedTeams, setMyAcceptedTeams] = useState('');
  const [versionID, setVersionID] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [applePay, setApplePay] = useState(false);

  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    const versionInfo = await fetchVersionInfo();
    const teams = await getTeams()
    setUserInfo(userDoc);
    setVersionID(versionInfo);
    setTeams(teams);

    let myOwnedTeams = [];
    let myTeamInvites = [];
    let myAcceptedTeams = [];

    teams.forEach(team => {
      if(team.owner === userDoc.uid){
        console.log("Adding team to myOwnedTeams: ", team.id);
        myOwnedTeams.push(team);
      }
      if(team.invited.includes(userDoc.uid)){
        console.log("Adding team to myTeamInvites: ", team.id);
        myTeamInvites.push(team);
      }
      if(team.accepted.includes(userDoc.uid)){
        console.log("Adding team to myAcceptedTeams: ", team.id);
        myAcceptedTeams.push(team);
      }
    });
    setMyOwnedTeams(myOwnedTeams);
    setMyTeamInvites(myTeamInvites);
    setMyAcceptedTeams(myAcceptedTeams);

    //console.log(window);    
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    document.title ="Transaction Minder: Teams";

    fetchData();
  }, [user, loading]);


  const handleJoinTeam = async(team, event) => {

    let teamCopy = JSON.parse(JSON.stringify(team));
    console.log("Join team: ", teamCopy);
    teamCopy.accepted.push(userInfo.uid);
    teamCopy.acceptedUsers.push(userInfo);
    
    let indexOfInvite = teamCopy.invited.indexOf(userInfo.uid);
    //console.log(indexOfInvite)
    teamCopy.invited.splice(indexOfInvite, 1);
    teamCopy.invitedUsers.splice(indexOfInvite, 1);

    //console.log(teamCopy.invited);
    //console.log(teamCopy.invitedUsers);
    
    await updateTeam(teamCopy);
    await fetchData();
  }

  const handleLeaveTeam= async(team, event) => {

    let teamCopy = JSON.parse(JSON.stringify(team));
    console.log("Leave team: ", teamCopy);
    
    let indexOfInvite = teamCopy.accepted.indexOf(userInfo.uid);
    teamCopy.accepted.splice(indexOfInvite, 1);
    teamCopy.acceptedUsers.splice(indexOfInvite, 1);
    
    await updateTeam(teamCopy);
    await fetchData();
  }

  let title = "Teams";
  let activeNav = "teams";

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Account", link: ""}, {title: title, link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !userInfo && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { userInfo && 
                <>
                <div className="card-header border-bottom">
                <h1><i className="bi-people nav-icon me-2"></i>Teams</h1>
                <h2 className="h6 text-muted">
                  Add teams, accept team invites or add members to your team below.
                </h2>
                
                </div>

                <div className="card-body">
                  <h3>Teams You Own</h3>

                  { (myOwnedTeams && myOwnedTeams.length > 0)
                  ?
                  myOwnedTeams.map(team => (
                    <div key={team.id}>
                      <p>
                        <a href={"/team/" + team.id}>{team.name}</a>
                      </p>
                    </div>
                  ))
                  : <p>No owned teams...</p>
                  }                  

                  <h3>Your Team Invites</h3>

                  { (myTeamInvites && myTeamInvites.length > 0)
                  ?
                  myTeamInvites.map(team => (
                    <div key={team.id}>
                      <p>
                        <a href={"/team/" + team.id}>{team.name}</a>
                        <button type="button" className="ms-5 btn btn-sm btn-primary" onClick={handleJoinTeam.bind(this, team)}><i className="me-2 bi bi-plus-square-fill"></i>Join Team</button>
                      </p>
                    </div>
                  ))
                  : <p>No team invites...</p>
                  }                  


                <h3>Teams You Belong To</h3>

                { (myAcceptedTeams && myAcceptedTeams.length > 0) 
                ?
                myAcceptedTeams.map(team => (
                  <div key={team.id}>
                    <p>
                      <a href={"/team/" + team.id}>{team.name}</a>
                      <button type="button" className="ms-5 btn btn-sm btn-primary" onClick={handleLeaveTeam.bind(this, team)}><i className="me-2 bi bi-dash-square-fill"></i>Leave Team</button>
                    </p>
                  </div>
                ))
                : <p>You do not belong to any teams...</p>
                }                  

                </div>

                
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Billing info updated...</p>                    
                </div>
            </div>
          </div>
      )}
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Teams;