import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import ReminderAdd from "./ReminderAdd";
import ReminderEdit from "./ReminderEdit";
import TransactionContacts from "./TransactionContacts";
import TransactionSendEmail from "./TransactionSendEmail";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { isBefore } from "date-fns";
import { currencyFormatter, titleCase, getTransactionsForUser, getTransactionsForUserList, getTeamsForOwner, addReminder, updateReminder, deleteReminder, deleteTransaction, dateString, closeTransaction, updateTransactionContacts, sendTransactionEmail } from "./firebaseData";
import { Timestamp } from "firebase/firestore";
import "./Dashboard.css";

function Dashboard(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [transactions, setTransactions] = useState('');

  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);
    if(props && props.list && props.list === "team"){
      let teams = await getTeamsForOwner(userDoc.uid)
      let directReports = [];
      teams.forEach(team => {
        console.log("User Owns Team: ", team)
        directReports = directReports.concat(team.accepted);
      });
      console.log("Direct Reports: ", directReports);
      setTransactions(await getTransactionsForUserList(directReports));
    } else {
      setTransactions(await getTransactionsForUser(user, props.status));
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    if (user){
      console.log('User UID: ', user.uid);
      fetchData();
  
    }
    //console.log(window);

  }, [user, loading]);

  const updateTransactionContactsFromModal = async(results) => {
    console.log('updateTransactionContactsFromModal Callback Fired!', results);
    await updateTransactionContacts(results.transactionID, results.emailList);
    setTransactions(await getTransactionsForUser(user));
  }

  const updateTransactionSendEmailFromModal= async(results) => {
    console.log('updateTransactionSendEmailFromModal Callback Fired!', results);
    await sendTransactionEmail(results);
  }

  const addReminderFromModal = async(reminder) => {
    console.log('addReminderFromModal Callback Fired!');

    reminder.reminderFrequency = "dailyUntilComplete";
    reminder.reminderEmails = [ user.email ];
    reminder.completed = false;
    console.log(reminder);
    await addReminder(reminder);
    setTransactions(await getTransactionsForUser(user));
  }

  const updateReminderFromModal = async(reminder) => {
    console.log('updateReminderFromModal Callback Fired!');
    reminder.updateTime = Timestamp.fromDate(new Date());
    console.log(reminder);
    await updateReminder(reminder);
    setTransactions(await getTransactionsForUser(user));
  }

  const toggleReminderStatus = (async (reminder, event) =>{
    reminder.completed = event.target.checked;
    console.log(reminder);
    await updateReminder(reminder);

    if(props && props.list && props.list === "team"){
      setTransactions(await getTransactionsForUserList(userInfo.directReports));
    } else {
      setTransactions(await getTransactionsForUser(user, props.status));
    }

  }); 

  const showAddReminderModal = (transactionID, event) => {
    console.log('Add Reminder to Transaction ID: ', transactionID);
    var modalAddReminder = new window.bootstrap.Modal(document.getElementById('modalAddReminder'), { backdrop: true, keyboard: true, focus: true});
    document.getElementById('addReminderForm').reset();

    document.getElementById("addReminderTitle").classList.remove('is-valid');
    document.getElementById("addReminderTitle").classList.remove('is-invalid');
    document.getElementById("addReminderDueDate").classList.remove('is-valid');
    document.getElementById("addReminderDueDate").classList.remove('is-invalid');
    document.getElementById('addReminderTransactionID').value = transactionID;
    modalAddReminder.show();
  }

  const showEditReminderModal = ((reminder, event) => {
    console.log('Edit Reminder!');
    console.log(reminder);

    var modalEditReminder = new window.bootstrap.Modal(document.getElementById('modalEditReminder'), { backdrop: true, keyboard: true, focus: true});
    
    document.getElementById("editReminderTitle").classList.remove('is-valid');
    document.getElementById("editReminderTitle").classList.remove('is-invalid');
    document.getElementById("editReminderDueDate").classList.remove('is-valid');
    document.getElementById("editReminderDueDate").classList.remove('is-invalid');
    document.getElementById('editReminderObject').value = JSON.stringify(reminder);
    document.getElementById('editReminderTitle').value = reminder.title;
    if(document.getElementById('editReminderDueDate') && reminder.dueDate){
      document.getElementById('editReminderDueDate').value = dateString(reminder.dueDate.toDate());
      document.getElementById('editReminderDueDate').selected = reminder.dueDate.toDate();  
    }
    //console.log(document.getElementById('editReminderDueDate'))
    //console.log(dateString(reminder.dueDate.toDate()));

    modalEditReminder.show();

  });

  const handleDeleteReminder = (async (reminder, event) => {
    if(window.confirm("Are you sure you want to delete this reminder?")){
      console.log('Delete Reminder!');
      console.log(reminder);
      await deleteReminder(reminder);

      if(props && props.list && props.list === "team"){
        setTransactions(await getTransactionsForUserList(userInfo.directReports));
      } else {
        setTransactions(await getTransactionsForUser(user, props.status));
      }
    }
  });

  const handleEditTransaction = ((transaction, event) =>{
    console.log('Edit Transaction!');
    console.log(transaction);
    navigate("/transaction/edit/" + transaction.id, { replace: false, state: props });
  });

  const handleCloseTransaction = (async (transaction, event) =>{
    console.log('Close Transaction!');
    console.log(transaction);
    transaction.status = 'closed'
    await closeTransaction(user, userInfo.name, transaction.id);    
    console.log('Transaction closed!');

    if(props && props.list && props.list === "team"){
      setTransactions(await getTransactionsForUserList(userInfo.directReports));
    } else {
      setTransactions(await getTransactionsForUser(user, props.status));
    }

  });

  const handleTransactionContacts = ((transaction, event) =>{
    console.log('Transaction Contacts!');
    console.log(transaction);
    let contactsTransactionIDElement = document.getElementById("contactsTransactionID");
    contactsTransactionIDElement.value = transaction.id;

    if(transaction.contactList){
      let formElement = document.getElementById("editTransactionContactList");  
      formElement.value = transaction.contactList;
    }

    var modalTransactionContacts = new window.bootstrap.Modal(document.getElementById('modalTransactionContacts'), { backdrop: true, keyboard: true, focus: true});
    modalTransactionContacts.show();

  });

  const handleEmailTransaction = ((transaction, event) =>{
    console.log('Email Transaction!');
    console.log(transaction);

    let emailTransactionIDlement = document.getElementById("emailTransactionID");
    emailTransactionIDlement.value = transaction.id;

    var modalTransactionSendEmail = new window.bootstrap.Modal(document.getElementById('modalTransactionSendEmail'), { backdrop: true, keyboard: true, focus: true});
    modalTransactionSendEmail.show();

  });

  const handleCopyTransaction = ((transaction, event) => {
    console.log('Copy to Clipboard!');
    console.log(transaction);

    let clipboardText = transaction.title;
    clipboardText += '\n';
    if(transaction.mls){
      clipboardText += 'MLS: ' + transaction.mls;
      clipboardText += '\n';  
    }
    clipboardText += 'Status: ' + titleCase(transaction.status);
    clipboardText += '\n';
    clipboardText += 'Accepted: ' + dateString(transaction.acceptDate.toDate());
    clipboardText += '\n';
    clipboardText += 'Close of Escrow: ' + dateString(transaction.closeDate.toDate());
    clipboardText += '\n';

    if(transaction.contractPrice){
      clipboardText += 'Contract Price: ' + currencyFormatter.format(transaction.contractPrice);
      clipboardText += '\n';
    }

    if(transaction.emdAmount){
      clipboardText += 'EMD: ' + currencyFormatter.format(transaction.emdAmount);
      clipboardText += '\n';
    }

    if(transaction.sellerCredit){
      clipboardText += 'Seller Credit: ' + currencyFormatter.format(transaction.sellerCredit);
      clipboardText += '\n';
    }

    if(transaction.homeProtectionPlanAmount){
      clipboardText += 'Home Protection Plan: ' + currencyFormatter.format(transaction.homeProtectionPlanAmount);
      clipboardText += '\n';
    }

    if(transaction.homeProtectionPlanPaidBy){
      clipboardText += 'Home Protection Paid By: ' + titleCase(transaction.homeProtectionPlanPaidBy);
      clipboardText += '\n';
    }

    clipboardText += '\n';

    for(let reminder of transaction.reminders){
      if(reminder.completed){
        clipboardText += "✓ "; 
      } else {
        clipboardText += "▢ ";
      }
      clipboardText += reminder.title;
      if(reminder.dueDate){
        clipboardText += ': ';
        clipboardText += dateString(reminder.dueDate.toDate()); 
      }
      clipboardText += '\n';  
    }


    console.log(clipboardText);
    navigator.clipboard.writeText(clipboardText);    

  })

  const handleDeleteTransaction = (async (transaction, event) => { 
    if(window.confirm("Are you sure you want to delete this transaction?")){
      console.log('Deleting Transaction!');
      await deleteTransaction(transaction);
      console.log('Transaction deleted!');
      window.location.reload();
    }
  });

  const handleAddTransaction = () => {
    console.log("Add transaction clicked... Passing to Page Header."); 
    let headerAddTransactionButton = document.getElementById("headerAddTransactionButton");
    if(headerAddTransactionButton){
      headerAddTransactionButton.click();
    }
  }


  //console.log('Rendering Dashboard...');
  let title = "Current Transactions";
  let activeNav = "transactions";
  let link = "/dashboard";
  let emptyMessage = 'No "Open" Transactions. Please add one...';

  if(props.status === "closed"){
    title = "Closed Transactions"
    activeNav = "closedTransactions";
    link = "/closedTransactions";
    emptyMessage = 'No "Closed" Transactions.';
  }
  if(props.list === "team"){
    title = "Direct Report Open Transactions"
    activeNav = "directReportsTransactions";
    link = "/teamTransactions";
    emptyMessage = 'No "Open" Transactions for Direct Reports';
  }
  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Transactions", link: "/dashboard"}, {title: title, link: ''}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                { false && 
                <div className="card-header border-bottom">
                  <form className="input-group input-group-merge">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi-search"></i>
                    </div>
                    <input type="search" className="form-control" placeholder="Search transactions" aria-label="Search transactions" />
                  </form>
                </div>
                }
                <div className="card-body">
                  <div className="mb-10">
                    { !transactions && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }

                    { (transactions && transactions.length === 0) &&
                      <div className="text-center">
                        <h2>{ emptyMessage }</h2>
                        <a className="btn btn-primary btn-transition" onClick={handleAddTransaction} ><i className="bi bi-plus-circle me-2"></i>Add Transaction</a>
                      </div>
                    }

                    { transactions && 
                    <ul className="list-unstyled mb-5">
                    { transactions.map(transaction => (
                          <li className="card card-bordered mb-3" key={transaction.id} id={transaction.id}>
                            <div className="card-body">
                              <h3 className="card-title">{ transaction.title }</h3>
                              {transaction.mls 
                              ? <h6 className="card-subtitle mb-2 text-muted border-top border-bottom py-2"><strong>MLS</strong>: <a className="fw-normal" target="_blank" rel="noopener noreferrer" href={"https://changevegas.com/listing/?id=" +  transaction.mls + "_28"}>{ transaction.mls }</a>&nbsp;&nbsp;&nbsp;<strong>Status</strong>: <span className="fw-normal">{ titleCase(transaction.status) }</span>&nbsp;&nbsp;&nbsp;<strong>Created By</strong>: <span className="fw-normal">{ transaction.createdBy.name }</span></h6>
                              : <h6 className="card-subtitle mb-2 text-muted border-top border-bottom py-2"><strong>Status</strong>: { titleCase(transaction.status) }&nbsp;&nbsp;&nbsp;<strong>Created By</strong>: { transaction.createdBy.name }</h6>
                              }
                              <div className="row">
                                <div className="col-md-8">
                                  {transaction.mls &&
                                  <div className="row text-black">
                                    <div className="col-md-6">APN:</div>
                                    <div className="col-md-6 text-end">{ transaction.apn }</div>
                                  </div>
                                  }

                                  <div className="row text-black">
                                    <div className="col-md-6">Accepted:</div>
                                    <div className="col-md-6 text-end">{ dateString(transaction.acceptDate.toDate()) }</div>
                                  </div>
                                  { transaction.status === 'open' && isBefore(transaction.closeDate.toDate(), new Date())
                                  ? <div className="row reminderPastDue">
                                      <div className="col-md-6">
                                      { transaction.side === 'lease' ? 'Move In Date:' : 'Close of Escrow:'}
                                      </div>
                                      <div className="col-md-6 text-end">{ dateString(transaction.closeDate.toDate()) }</div>
                                    </div>
                                  : <div className="row text-black">
                                      <div className="col-md-6">
                                      { transaction.side === 'lease' ? 'Move In Date:' : 'Close of Escrow:'}
                                      </div>
                                      <div className="col-md-6 text-end">{ dateString(transaction.closeDate.toDate()) }</div>
                                    </div>
                                  }
                                  
                                  { (!!transaction.contractPrice) &&
                                  <div className="row text-black">
                                    <div className="col-md-6">Contract Price:</div>
                                    <div className="col-md-6 text-end">{ currencyFormatter.format(transaction.contractPrice) }</div>
                                  </div>
                                  }
                                  { (!!transaction.emdAmount) &&
                                  <div className="row text-black">
                                    <div className="col-md-6">EMD:</div>
                                    <div className="col-md-6 text-end">{ currencyFormatter.format(transaction.emdAmount) }</div>
                                  </div>
                                  }

                                  { (!!transaction.sellerCredit) &&
                                  <div className="row text-black">
                                    <div className="col-md-6">Seller Credit:</div>
                                    <div className="col-md-6 text-end">{ currencyFormatter.format(transaction.sellerCredit) }</div>
                                  </div>
                                  }

                                  { (!!transaction.homeProtectionPlanAmount) &&
                                  <div className="row text-black">
                                    <div className="col-md-6">Home Protection Plan:</div>
                                    <div className="col-md-6 text-end">{ currencyFormatter.format(transaction.homeProtectionPlanAmount) }</div>
                                  </div>
                                  }
                                  { transaction.homeProtectionPlanPaidBy &&
                                  <div className="row text-black">
                                    <div className="col-md-6">Home Protection Paid By:</div>
                                    <div className="col-md-6 text-end">{ titleCase(transaction.homeProtectionPlanPaidBy) }</div>
                                  </div>
                                  }

                                  { (transaction.contactList && transaction.contactList.length > 0) &&
                                  <div className="row text-muted mt-2">
                                    <div className="col small">Update .cc list: { transaction.contactList.join(", ") }</div>
                                  </div>
                                  }

                                </div>
                                <div className="col-md-4">
                                  <div className="d-grid gap-2">
                                    <button className="btn btn-sm btn-primary" onClick={handleEditTransaction.bind(this, transaction)}><i className="bi bi-pen small me-2"></i>Edit<span className="d-none d-xl-inline"> Transaction</span></button>
                                    { transaction.status === 'open' && isBefore(transaction.closeDate.toDate(), new Date()) &&
                                    <button className="btn btn-sm btn-success" onClick={handleCloseTransaction.bind(this, transaction)}><i className="bi bi-check-circle-fill me-2"></i>Close<span className="d-none d-xl-inline"> Transaction</span></button>
                                    }

                                    <button className="btn btn-sm btn-secondary" onClick={handleTransactionContacts.bind(this, transaction)}><i className="bi bi-person-rolodex  me-2"></i><span className="d-none d-xl-inline">Transaction </span>Contacts</button>
                                    <button className="btn btn-sm btn-secondary" onClick={handleEmailTransaction.bind(this, transaction)}><i className="bi bi-envelope me-2"></i>Email <span className="d-none d-xl-inline">Transaction</span></button>
                                    
                                    <button className="btn btn-sm btn-white" onClick={handleCopyTransaction.bind(this, transaction)}><i className="bi bi-clipboard-plus me-2"></i>Copy<span className="d-none d-xl-inline"> to Clipboard</span></button>
                                    <button className="btn btn-sm btn-white" onClick={handleDeleteTransaction.bind(this, transaction)}><i className="bi bi-trash me-2"></i>Delete<span className="d-none d-xl-inline"> Transaction</span></button>
                                  </div>                                
                                </div>
                              </div>

                            </div>

                            { (transaction.reminders && transaction.reminders.length > 0) &&
                            <div className="mx-4 my-2">
                              <h5 className="card-title mb-3"><i className="bi bi-alarm"></i>&nbsp;Reminders <a title="Add Reminder" className="btn btn-sm btn-success ms-2" onClick={showAddReminderModal.bind(this, transaction.id)}><i className="bi bi-plus-circle me-2"></i>Add Reminder</a></h5>
                              <div className="small">
                                <div className="row border-bottom fw-bold">
                                    <div className="col-md-2">Actions</div>
                                    { (transaction.reminderType === "milestone completion") &&
                                      <div className="col-md-10">Task</div>
                                    }
                                    { (transaction.reminderType !== "milestone completion") &&
                                    <>
                                    <div className="col-md-8">Task</div>
                                    <div className="col-md-2">Due Date</div>
                                    </>
                                    }
                                </div>
                                <div>
                                { transaction.reminders.map(reminder => (  
                                  <div className="row my-2" key={reminder.id} id={reminder.id}>
                                    <div className="col-md-2">
                                      <input title="Mark Reminder Complete" className="form-check-input" type="checkbox" checked={reminder.completed} onChange={toggleReminderStatus.bind(this, reminder)} id={'completeReminder-' + reminder.id} />
                                      <a title="Edit Reminder" className="reminder-action" onClick={showEditReminderModal.bind(this, reminder)}><i className="bi bi-pen small ms-2"></i></a>
                                      <a title="Delete Reminder" className="reminder-action ms-2" onClick={handleDeleteReminder.bind(this, reminder)}><i className="bi bi-trash"></i></a>
                                    </div>
                                    { (transaction.reminderType === "milestone completion") &&
                                    <div className="col-md-10 text-black"><span className={reminder.className}>{ reminder.title } { reminder.helperText ? <span className="text-muted small ms-2"> ({ reminder.helperText }) </span> :"" }</span></div>
                                    }
                                    { (transaction.reminderType !== "milestone completion") &&
                                    <>
                                    <div className="col-md-8 text-black"><span className={reminder.className}>{ reminder.title } { reminder.helperText ? <span className="text-muted small ms-2"> ({ reminder.helperText }) </span> :"" }</span></div>
                                    <div className="col-md-2 text-black"><span className={reminder.className}>{ dateString(reminder.dueDate.toDate()) }</span></div>
                                    </>
                                    }
                                  </div>
                                ))}
                                </div>
                              </div>
                            </div>
                            }
                          </li>
                    ))}
                    </ul>
                    }
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
      <ReminderEdit callback={updateReminderFromModal} />
      <ReminderAdd callback={addReminderFromModal} />
      <TransactionContacts callback={updateTransactionContactsFromModal} />
      <TransactionSendEmail callback={updateTransactionSendEmailFromModal} />
    </>
  );
}

export default Dashboard;