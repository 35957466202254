import React from "react";
import { logout } from "./firebaseAuth";
import "./PageTop.css";

function PageTop(props) {

  return (

    <div className="navbar-dark bg-dark" style={{ backgroundImage: `url('./assets/svg/components/wave-pattern-light.svg')`}}>
      <div className="container content-space-1 content-space-b-lg-3">
        <div className="row align-items-center">
          <div className="col">
            <div className="d-none d-lg-block">
              <h1 className="h2 text-white">Transaction Minder</h1>
            </div>

            { props.breadcrumbs && 
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light mb-0">
              { props.breadcrumbs.map((breadcrumb, index) => (
                breadcrumb.link 
                  ? <li key={index} className="breadcrumb-item"><a href={breadcrumb.link}>{breadcrumb.title}</a></li>
                  : <li key={index} className="breadcrumb-item">{breadcrumb.title}</li>
              ))}
              </ol>
            </nav>
            }
          </div>

          <div className="col-auto text-end">
            <div className="d-none d-lg-block">

                {props.userInfo 
                ?
                  <div className="small text-center">
                    <button className="btn btn-soft-light btn-sm mt-2" onClick={logout}>Log out</button>
                  </div>
                :
                  <div className="small text-center">
                    <a className="btn btn-soft-light btn-sm mt-2" href="/login">Login</a>
                  </div>
                }


            </div>

            <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-default">
                <i className="bi-list"></i>
              </span>
              <span className="navbar-toggler-toggled">
                <i className="bi-x"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

);
}

export default PageTop;
