import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import { titleCase, getTransactionTemplate, updateTransactionTemplate } from "./firebaseData";
import "./TemplateEdit.css";

import Table from "./components/Table";


function TemplateEdit(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [template, setTemplate] = useState('');
  const [templateFields, setTemplateFields] = useState('');
  const [templateReminders, setTemplateReminders] = useState('');
  
  const [templateID, setTemplateID] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateReminderType, setTemplateReminderType] = useState('daily details');
  const [templatePublished, setTemplatePublished] = useState(false);

  const [editFieldName, setEditFieldName] = useState('');
  const [editFieldLabel, setEditFieldLabel] = useState('');
  const [editFieldRequired, setEditFieldRequired] = useState(false);
  const [editFieldType, setEditFieldType] = useState('');
  const [editFieldChoices, setEditFieldChoices] = useState([]);
  const [editFieldHelperText, setEditFieldHelperText] = useState('');

  const [editReminderName, setEditReminderName] = useState('');
  const [editReminderLabel, setEditReminderLabel] = useState('');
  const [editReminderRequired, setEditReminderRequired] = useState(false);
  const [editReminderEditable, setEditReminderEditable] = useState(false);
  const [editReminderType, setEditReminderType] = useState('');
  const [editReminderValue, setEditReminderValue] = useState('');
  const [editReminderDisplayRule, setEditReminderDisplayRule] = useState('');
  const [editReminderHelperText, setEditReminderHelperText] = useState('');


  const editFieldModalRef = useRef();
  const editReminderModalRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  const reminderColumns = React.useMemo(
    () => [
      { Header: 'Reminders',
        columns: [
        { id: 'label', Header: 'Label', accessor: 'label'},
        { id: 'name', Header: 'Name', accessor: 'name'},
        { id: 'required', Header: 'Required', accessor: (row) => {return row['required'] ? <div className="text-center"><i className="ms-2 bi bi-check-circle-fill"></i></div> : <div className="text-center"><i className="ms-2 bi bi-circle"></i></div>} },
        { id: 'editable', Header: 'Editable', accessor: (row) => {return row['editable'] ? <div className="text-center"><i className="ms-2 bi bi-check-circle-fill"></i></div> : <div className="text-center"><i className="ms-2 bi bi-circle"></i></div>} },
        { id: 'type', Header: 'Type', accessor: (row) => {return titleCase(row['type'])}},
        { id: 'value', Header: 'Value', accessor: 'value'},
        { id: 'actions', Header: 'Actions', accessor: (row, rowIndex) => <a alt="Edit / Delete Reminder" title="Edit / Delete Reminder" className="primary-link" onClick={handleEditReminder.bind(null, row, rowIndex)}><i className="bi bi-pencil-square me-1"></i></a> }
      ]}
    ], []
  )

  const fieldColumns = React.useMemo(
    () => [
      { Header: 'Fields',
        columns: [
        { id: 'label', Header: 'Label', accessor: 'label'},
        { id: 'name', Header: 'Name', accessor: 'name'},
        { id: 'required', Header: 'Required', accessor: (row) => {return row['required'] ? <div className="text-center"><i className="ms-2 bi bi-check-circle-fill"></i></div> : <div className="text-center"><i className="ms-2 bi bi-circle"></i></div>} },
        { id: 'type', Header: 'Type', accessor: (row) => {return titleCase(row['type'])}},
        { id: 'actions', Header: 'Actions', accessor: (row, rowIndex) => <a alt="Edit / Delete Field" title="Edit / Delete Field" className="primary-link" id={"editField-" + rowIndex} onClick={handleEditField.bind(null, row, rowIndex)}><i className="bi bi-pencil-square me-1"></i></a> }
      ]}
    ], []
  )

  const params = useParams();
  console.log("Displaying / Editing Template: ", props, params, template);
  
  async function fetchData(){
    setUserInfo(await getUser(user));
    const versionInfo = await fetchVersionInfo();
    setVersionID(versionInfo.versionID)
    const theTemplate = await getTransactionTemplate(params.templateID);
    setTemplateFields(theTemplate.fields);
    setTemplateReminders(theTemplate.reminders);
    setTemplateID(params.templateID);
    setTemplateName(theTemplate.name);
    setTemplateTitle(theTemplate.title);
    setTemplateReminderType(theTemplate.reminderType);
    setTemplatePublished(theTemplate.published);
    setTemplate(theTemplate);
    document.title = "Transaction Minder: Template Details: " + theTemplate.title;
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    console.log('UseEffect Fired @ TemplateEdit Level...');
    fetchData();
  }, [user, loading]);


  const handleSave = async (event) => {
    event.preventDefault();
    console.log("Form submitted...");
    let updatedTemplate = {
      owner: template.owner,
      order: template.order,
      id: template.id,
      name: templateName,
      title: templateTitle,
      reminderType: templateReminderType,
      published: (templatePublished),
      fields: templateFields,
      reminders: templateReminders  
    }
    await updateTransactionTemplate(updatedTemplate);
    fetchData();
  }

  const handleAddField = () => {
    console.log("Handle Add Field");
    //TODO: Add Logic...
    resetEditFields();
    document.getElementById("editFieldDialogLabel").innerText = 'Add Field';
    document.getElementById("deleteFieldButton").style.display = "none";

    document.getElementById("editFieldName").readOnly = false;
    document.getElementById("editFieldName").disabled = false;
    document.getElementById("editFieldName").focus();
    const editFieldModal = editFieldModalRef.current;
    const bsModal = new bootstrap.Modal(editFieldModal);
    bsModal.show();      
}

  const resetEditFields = () => {
    console.log("Reset Edit Fields Called...");
    setEditFieldName('');
    setEditFieldLabel('');
    setEditFieldRequired(false);
    setEditFieldType('text');
    setEditFieldChoices([]);
    setEditFieldHelperText('');    

  }

  const handleEditField = (theField, index) => {

    if(theField){
      console.log("Handle Edit Field: ", theField.name);
      setEditFieldName(theField.name);
      setEditFieldLabel(theField.label);
      setEditFieldRequired(theField.required);
      setEditFieldType(theField.type);
      setEditFieldChoices(theField.choices);
      setEditFieldHelperText(theField.helperText);    
      document.getElementById("editFieldDialogLabel").innerText = 'Edit Field';
      document.getElementById("deleteFieldButton").style.display = "block";
      document.getElementById("editFieldName").readOnly = true;
      document.getElementById("editFieldName").disabled = true;
      document.getElementById("editFieldLabel").focus();
  
      const editFieldModal = editFieldModalRef.current;
      const bsModal = new bootstrap.Modal(editFieldModal);
      bsModal.show();      
  
    }
  }

  const handleHideEditFieldModal = () => {
    const editFieldModal = editFieldModalRef.current;
    const bsModal = bootstrap.Modal.getInstance(editFieldModal);
    bsModal.hide();    

    [...document.getElementsByClassName("modal-backdrop")].forEach((element) => {
      element.remove();
    });

  }

  const handleSaveFieldEdit = (event) => {
    console.log("Handle Save Edit Field.");

    let fieldIndex = template.fields.findIndex((field) => {
      return (field.name === editFieldName)
    });

    console.log("Found Field:", editFieldName, "at", fieldIndex);

    let editFields = [...templateFields];
    if (fieldIndex !== -1){
      editFields[fieldIndex] = { 
        id: editFieldName,
        name: editFieldName,
        helperText: editFieldHelperText,
        label: editFieldLabel,
        required: editFieldRequired,
        type: editFieldType
      }
      if(editFieldChoices){
        editFields[fieldIndex].choices = editFieldChoices;
      }

      console.log("New Field Array: ", editFields)
      setTemplate({ ...template, fields: editFields });
      setTemplateFields(editFields);
    } else {
      //adding new field
      console.log("Handle ADDING New Field.");

      let addField = { 
        id: editFieldName,
        name: editFieldName,
        helperText: editFieldHelperText,
        label: editFieldLabel,
        required: editFieldRequired,
        type: editFieldType
      }
      editFields.push(addField);
      console.log("New Field Values: ", addField);
      console.log("New Field Array: ", editFields)
      setTemplate({ ...template, fields: editFields });
      setTemplateFields(editFields);
    }
    
    const editFieldModal = editFieldModalRef.current;
    const bsModal = bootstrap.Modal.getInstance(editFieldModal);
    bsModal.hide();    

    [...document.getElementsByClassName("modal-backdrop")].forEach((element) => {
      element.remove();
    });

  }

  const handleDeleteField = (event) => {
    console.log("Handle Delete Field");

    if(templateFields){

      let fieldIndex = template.fields.findIndex((field) => {
        return (field.name === editFieldName)
      });
  
      console.log("Found Field:", editFieldName, "at", fieldIndex);
    
      console.log("Found Field to Delete:", editFieldName, "at", fieldIndex);
      if (confirm("Are you sure you want to delete the field '" + editFieldName + "'?")) {
  
        let editFields = [...templateFields];
        if (fieldIndex !== -1){ 
          editFields.splice((fieldIndex), 1);  
        }
        console.log("Fields After Edit: ", editFields);
        setTemplate({ ...template, fields: editFields });
        setTemplateFields(editFields);
      }

      const editFieldModal = editFieldModalRef.current;
      const bsModal = bootstrap.Modal.getInstance(editFieldModal);
      bsModal.hide();    
  
      [...document.getElementsByClassName("modal-backdrop")].forEach((element) => {
        element.remove();
      });
  
  
    } else {
      console.log("Template fields returned undefined...");
    }



  }

  const handleAddReminder = () => {
    console.log("Handle Add Reminder");

    resetReminderFields();
    document.getElementById("editReminderDialogLabel").innerText = 'Add Reminder';
    document.getElementById("deleteReminderButton").style.display = "none";
    document.getElementById("editReminderName").readOnly = false;
    document.getElementById("editReminderName").disabled = false;
    document.getElementById("editReminderName").focus();

    const editReminderModal = editReminderModalRef.current;
    const bsModal = new bootstrap.Modal(editReminderModal);
    bsModal.show();      
  }

  const resetReminderFields = () => {
    console.log("Reset Edit Reminder Called...");
    setEditReminderName('');
    setEditReminderLabel('');
    setEditReminderRequired(false);
    setEditReminderEditable(true);
    setEditReminderType('business days from acceptance date');
    setEditReminderValue(1);
    setEditReminderHelperText('');
    setEditReminderDisplayRule('');
  }


  const handleEditReminder = (theReminder, index) => {
    console.log("Handle Edit Reminder: ", theReminder, index)

    if(theReminder){
      console.log("Handle Edit Reminder: ", theReminder.name);
      setEditReminderName(theReminder.name);
      setEditReminderLabel(theReminder.label);
      setEditReminderRequired(theReminder.required);
      setEditReminderEditable(theReminder.editable);
      setEditReminderType(theReminder.type);
      setEditReminderValue(theReminder.value);
      setEditReminderHelperText(theReminder.helperText);
      if(theReminder.displayIf && theReminder.displayIf.length === 3){
        setEditReminderDisplayRule(theReminder.displayIf.join(","));
      } else {
        setEditReminderDisplayRule('');
      }

      document.getElementById("editReminderDialogLabel").innerText = 'Edit Reminder';
      document.getElementById("deleteReminderButton").style.display = "block";

      const editReminderModal = editReminderModalRef.current;
      const bsModal = new bootstrap.Modal(editReminderModal);
      bsModal.show();      
  
    }

  }

  const handleHideEditReminderModal = () => {
    const editReminderModal = editReminderModalRef.current;
    const bsModal = bootstrap.Modal.getInstance(editReminderModal);
    bsModal.hide();    

    [...document.getElementsByClassName("modal-backdrop")].forEach((element) => {
      element.remove();
    });

  }

  const handleSaveReminderEdit = (event) => {
    console.log("Handle Save Edit Field: ", event);

    let reminderIndex = template.reminders.findIndex((reminder) => {
      return (reminder.name === editReminderName)
    });

    console.log("Found Reminder:", editReminderName, "at", reminderIndex);

    let editReminders = [...templateReminders];

    if (reminderIndex !== -1){
      editReminders[reminderIndex] = { 
        id: editReminderName,
        name: editReminderName,
        helperText: editReminderHelperText,
        label: editReminderLabel,
        required: editReminderRequired,
        editable: editReminderEditable,
        type: editReminderType,
        value: editReminderValue
      }
      if(editReminderDisplayRule){
        editReminders[reminderIndex].displayIf = editReminderDisplayRule.split(",");
      }

      console.log("New Reminder Array: ", editReminders)
      setTemplate({ ...template, reminders: editReminders });
      setTemplateReminders(editReminders);
    }  else {
      //adding new field
      console.log("Handle ADDING New Field.");

      let addReminder = { 
        id: editReminderName,
        name: editReminderName,
        helperText: editReminderHelperText,
        label: editReminderLabel,
        required: editReminderRequired,
        editable: editReminderEditable,
        type: editReminderType,
        value: editReminderValue
      }

      if(editReminderDisplayRule){
        addReminder[reminderIndex].displayIf = editReminderDisplayRule.split(",");
      }

      editReminders.push(addReminder);
      console.log("New Reminders Values: ", addReminder);
      console.log("New Field Array: ", editReminders)
      setTemplate({ ...template, reminders: editReminders });
      setTemplateReminders(editReminders);
    }
        
    const editReminderModal = editReminderModalRef.current;
    const bsModal = bootstrap.Modal.getInstance(editReminderModal);
    bsModal.hide();    

    [...document.getElementsByClassName("modal-backdrop")].forEach((element) => {
      element.remove();
    });

  }

  const handleDeleteReminder = (reminderName, index, event) => {
    console.log("Handle Delete Reminder: ", reminderName, index, event);

    let reminderIndex = template.reminders.findIndex((reminder) => {
      return (reminder.name === editReminderName)
    });

    console.log("Found Reminder:", editReminderName, "at", reminderIndex);

    if (reminderIndex !== -1){

      if (confirm("Are you sure you want to delete the reminder '" + editReminderName + "'?")) {
        let editReminders = [...templateReminders];
        if (reminderIndex !== -1){ 
          editReminders.splice((reminderIndex), 1);  
        }

        console.log("New Reminder Array: ", editReminders)
        setTemplate({ ...template, reminders: editReminders });
        setTemplateReminders(editReminders);
      }
    }
        
    const editReminderModal = editReminderModalRef.current;
    const bsModal = bootstrap.Modal.getInstance(editReminderModal);
    bsModal.hide();    

    [...document.getElementsByClassName("modal-backdrop")].forEach((element) => {
      element.remove();
    });

  }

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Transactions", link: ""}, {title: "Templates", link: '/templates'}, {title: template.id, link: '/template/' + template.id}, {title: titleCase(props.action), link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="templates" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !template && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { template && 
                <form id="editTemplateForm" method="post" onSubmit={handleSave} className="needs-validation" noValidate>

                <div className="card-header border-bottom clearfix">
                  <h1 className="h2 mb-0 clearfix"><i className="bi bi-files nav-icon me-2"></i>{titleCase(props.action)} Template
                  <button className="btn btn-primary float-end" onClick={handleSave}><i className="bi bi-save me-2"></i>Save</button>
                  </h1>
                </div>

                <div className="card-body">
                  <div className="row text-black">
                  { template.id &&
                    <div className="form-floating mb-2">
                      <input type="text" className="form-control" name="templateID" id="templateID" value={templateID} readOnly={true} disabled={true} />
                      <label htmlFor="templateID" className="form-label">Template ID</label>
                    </div>
                  }
                    <div className="form-floating mb-2">
                      <input type="text" className="form-control" name="templateName" id="templateName" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                      <label htmlFor="templateName" className="form-label">Template Name</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input type="text" className="form-control" name="templateTitle" id="templateTitle" value={templateTitle} onChange={(e) => setTemplateTitle(e.target.value)} />
                      <label htmlFor="templateTitle" className="form-label">Template Title</label>
                    </div>
                    <div className="form-floating mb-2">
                      <select id="templateReminderType" name="templateReminderType" className="form-select" aria-label="Team Type" value={templateReminderType}  onChange={(e) => setTemplateReminderType(e.target.value)}>
                        <option value="daily details">Daily Details</option>
                        <option value="milestone completion">Milestone Completion</option>
                      </select>
                      <label htmlFor="templateReminderType" className="form-label">Reminder Type</label>
                    </div>

                    <div className="form-floating mb-2">
                      <select id="templatePublished" name="templatePublished" className="form-select" aria-label="Team Type" value={templatePublished.toString()}  onChange={(e) => setTemplatePublished(e.target.value === 'true')}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </select>
                      <label htmlFor="templateReminderType" className="form-label">Published</label>
                    </div>

                  </div>


                  <div className="mt-5">
                    <h4 className="clearfix">Fields
                      <small className="ms-2 fw-normal text-info">(drag and drop rows to reorder)</small>
                      <a alt="Add Field" title="Add Field" name="addField" id="addField" className="primary-link float-end fw-normal fs-6" onClick={handleAddField}><i className="bi bi-plus-square-fill me-2"></i>Add Field</a>
                    </h4>

                    {template.fields && template.fields.length > 0 &&
                    <Table columns={fieldColumns} data={templateFields} setData={setTemplateFields}></Table>
                    }
                  </div>

   

    


                  <div className="mt-5">
                    <h4 className="clearfix">Reminders
                      <small className="ms-2 fw-normal text-info">(drag and drop rows to reorder)</small>
                      <a alt="Add Reminder" title="Add Reminder" name="addReminder" id="addReminder" className="primary-link float-end fw-normal fs-6" onClick={handleAddReminder}><i className="bi bi-plus-square-fill me-2"></i>Add Reminder</a>

                    </h4>
                    {template.reminders && template.reminders.length > 0 &&
                    <Table columns={reminderColumns} data={templateReminders} setData={setTemplateReminders}></Table>
                    }
                  </div>


                </div>
                <div className="clearfix mb-3">
                <button className="btn btn-primary float-end me-5" onClick={handleSave}><i className="bi bi-save me-2"></i>Save</button>
                </div>
                </form>
              }                
              </div>
            </div> 
          </div>
        </div>


      <div className="modal fade" id="editFieldModal" tabIndex="-1" aria-labelledby="editFieldDialogLabel" aria-hidden="true" ref={editFieldModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="editFieldDialogLabel">Edit Field</h2>
              <button type="button" className="btn-close"  onClick={handleHideEditFieldModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">

                <div className="form-floating mb-2">
                    <input type="text" className="form-control" readOnly={true} disabled={true} name="editFieldName" id="editFieldName" value={editFieldName} onChange={(e) => setEditFieldName(e.target.value)} />
                    <label htmlFor="editFieldName" className="form-label">Field Name</label>
                </div>

                <div className="form-floating mb-2">
                    <input type="text" className="form-control" name="editFieldLabel" id="editFieldLabel" value={editFieldLabel} onChange={(e) => setEditFieldLabel(e.target.value)} />
                    <label htmlFor="editFieldLabel" className="form-label">Field Label</label>
                </div>

                <div className="form-floating mb-2">
                    <input type="text" className="form-control" name="editFieldHelperText" id="editFieldHelperText" value={editFieldHelperText} onChange={(e) => setEditFieldHelperText(e.target.value)} />
                    <label htmlFor="editFieldHelperText" className="form-label">Helper Text</label>
                </div>

                <div className="form-floating mb-2">

                    <select id="editFieldType" name="editFieldType" className="form-select" aria-label="Field Type" value={editFieldType}  onChange={(e) => setEditFieldType(e.target.value)}>
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="date">Date</option>
                        <option value="choice">Choice</option>
                    </select>

                    <label htmlFor="editFieldType" className="form-label">Field Type</label>

                </div>

                {editFieldType === 'choice' &&
                
                <div className="form-floating mb-2">
                    <textarea className="form-control" name="editFieldChoices" id="editFieldChoices" value={editFieldChoices.join('\n')} onChange={(e) => setEditFieldChoices(e.target.value.split('\n'))} />
                    <label htmlFor="editFieldChoices" className="form-label">Field Choices (one per line)</label>
                </div>
                
                }

                <div className="form-check mb-2">
                  <input className="form-check-input" type="checkbox" name="editFieldRequired" id="editFieldRequired" checked={editFieldRequired}  onChange={(e) => setEditFieldRequired(e.target.checked)} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">Required?</label>
                </div>                


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleHideEditFieldModal}>Close</button>
              <button id="deleteFieldButton" type="button" className="btn btn-danger" onClick={handleDeleteField}><i className="bi bi-trash-fill me-1"></i>Delete Field</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveFieldEdit}><i className="bi bi-save me-1"></i>Save Field</button>
            </div>
          </div>
        </div>
      
      </div>


      <div className="modal fade" id="editReminderModal" tabIndex="-1" aria-labelledby="editReminderDialogLabel" aria-hidden="true" ref={editReminderModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="editReminderDialogLabel">Edit Reminder</h2>
              <button type="button" className="btn-close"  onClick={handleHideEditReminderModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">

                <div className="form-floating mb-2">
                    <input type="text" className="form-control" readOnly={true} disabled={true}  name="editReminderName" id="editReminderName" value={editReminderName} onChange={(e) => setEditReminderName(e.target.value)} />
                    <label htmlFor="editReminderName" className="form-label">Reminder Name</label>
                </div>

                <div className="form-floating mb-2">
                    <input type="text" className="form-control" name="editReminderLabel" id="editReminderLabel" value={editReminderLabel} onChange={(e) => setEditReminderLabel(e.target.value)} />
                    <label htmlFor="editReminderLabel" className="form-label">Reminder Label</label>
                </div>

                <div className="form-floating mb-2">
                    <input type="text" className="form-control" name="editReminderHelperText" id="editReminderHelperText" value={editReminderHelperText} onChange={(e) => setEditReminderHelperText(e.target.value)} />
                    <label htmlFor="editReminderHelperText" className="form-label">Helper Text</label>
                </div>

                <div className="form-floating mb-2">

                    <select id="editReminderType" name="editReminderType" className="form-select" aria-label="Reminder Type" value={editReminderType}  onChange={(e) => setEditReminderType(e.target.value)}>
                        <option value="calendar days from accepted date">Calendar Days from Accepted Date</option>
                        <option value="business days from accepted date">Business Days from Accepted Date</option>
                        <option value="calendar days before close of escrow date">Calendar Days Before Close of Escrow Date</option>
                        <option value="business days before close of escrow date">Business Days Before Close of Escrow Date</option>
                    </select>

                    <label htmlFor="editReminderType" className="form-label">Reminder Type</label>

                </div>

                <div className="form-check mb-2">
                  <input className="form-check-input" type="checkbox" name="editReminderRequired" id="editReminderRequired" checked={editReminderRequired}  onChange={(e) => setEditReminderRequired(e.target.checked)} />
                  <label className="form-check-label" htmlFor="editReminderRequired">Required?</label>
                </div>                

                <div className="form-check mb-2">
                  <input className="form-check-input" type="checkbox" name="editReminderEditable" id="editReminderEditable" checked={editReminderEditable}  onChange={(e) => setEditReminderEditable(e.target.checked)} />
                  <label className="form-check-label" htmlFor="editReminderEditable">Editable?</label>
                </div>             

                <div className="form-floating mb-2">
                  <input type="number" className="form-control" name="editReminderValue" id="editReminderValue" value={editReminderValue} onChange={(e) => setEditReminderValue(parseInt(e.target.value))} />
                  <label htmlFor="editReminderValue" className="form-label">Value</label>
                </div>
   
                <div className="form-floating mb-2">

                    <select id="editReminderDisplayRule" name="editReminderDisplayRule" className="form-select" aria-label="Reminder Type" value={editReminderDisplayRule}  onChange={(e) => setEditReminderDisplayRule(e.target.value)}>
                        <option value=''>Always Display</option>
                        <option value="financing,===,loan">Financing == Loan</option>
                        <option value="cic,===,yes">CIC == Yes</option>
                        <option value="homeProtectionPlanAmount,>,0">Home Protection Plan Amount &gt; $0.00</option>
                    </select>

                    <label htmlFor="editReminderDisplayRule" className="form-label">Display If...</label>

                </div>




            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleHideEditReminderModal}>Close</button>
              <button id="deleteReminderButton" type="button" className="btn btn-danger" onClick={handleDeleteReminder}><i className="bi bi-trash-fill me-1"></i>Delete Reminder</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveReminderEdit}><i className="bi bi-save me-1"></i>Save Reminder</button>
            </div>
          </div>
        </div>
      
      </div>

      </main>

      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default TemplateEdit;