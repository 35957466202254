import React, {useState} from "react";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker"; // From https://reactdatepicker.com/
import { Timestamp } from "firebase/firestore";
import { dateMask, dateMaskPipe, updateReminder } from "./firebaseData";
import "./ReminderAdd.css";
import "react-datepicker/dist/react-datepicker.css";

function ReminderAdd(props) {

  const [dueDate, setDueDate] = useState(new Date());


  const handleSaveReminder = (async (event) => { 
    event.preventDefault();

    console.log('Add Reminder - Save Event');

    const form = document.getElementById('addReminderForm');
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    //Validate
    var valid = true;
    var titleElement = document.getElementById("addReminderTitle");
    var dueDateElement = document.getElementById("addReminderDueDate");

    if(!formJson.reminderTitle){
      valid = false;
      titleElement.classList.remove('is-valid');
      titleElement.classList.add('is-invalid');
    } else {
      titleElement.classList.remove('is-invalid');
      titleElement.classList.add('is-valid');
    }

    let dueDate = new Date(formJson.reminderDueDate);
    if(isNaN(dueDate)){
      valid = false;
      dueDateElement.classList.remove('is-valid');
      dueDateElement.classList.add('is-invalid');
    } else {
      dueDateElement.classList.remove('is-invalid');
      dueDateElement.classList.add('is-valid');
    }

    if(!valid){
      form.classList.add('hasValidation');
      return(false);
    } else {
      form.classList.remove('hasValidation');
    }

    //If Valid..
    let reminder = {}; //JSON.parse(formJson.addReminderObject);

    reminder.title = formJson.reminderTitle;
    reminder.dueDate = Timestamp.fromDate(new Date(formJson.reminderDueDate));
    reminder.parentID = formJson.addReminderTransactionID;
   


    //Manually hide modal...
    document.getElementById('modalAddReminder').classList.remove('show');
    document.body.classList.remove('modal-open');
    document.getElementsByClassName('modal-backdrop')[0].remove();
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('padding-right');

    props.callback(reminder);

    //window.location.reload();
  });


  return (
    <>
      <div className="modal fade" id="modalAddReminder" tabIndex="-1" aria-labelledby="modalAddReminderLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form id="addReminderForm" method="post" onSubmit={handleSaveReminder} className="needs-validation" noValidate>
              <div className="modal-header">
                <h3 className="modal-title" id="modalAddReminderLabel">Add Reminder</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <input type="hidden" id="addReminderTransactionID" name="addReminderTransactionID" />
                <div className="mb-3">
                  <label htmlFor="addReminderTitle" className="form-label">Reminder Title</label>
                  <input type="text" id="addReminderTitle" name="reminderTitle" className="form-control" placeholder="Reminder Title" aria-label="Reminder Title" required/>
                  <span className="form-text"><sup className="text-danger">*</sup>Required.</span>
                </div>

                <div className="mb-3">
                  <label htmlFor="addReminderDueDate" className="form-label">Reminder Due Date</label>
                  <DatePicker className="form-control" selected={dueDate} id="addReminderDueDate" name="reminderDueDate" required onChange={(date) => setDueDate(date)} todayButton="Today" showPopperArrow={false} />
                  { false && 
                  <MaskedInput mask={dateMask} pipe={dateMaskPipe} className="form-control" id="addReminderDueDate" name="reminderDueDate" placeholder="MM/DD/YYYY" required />  
                  }
                  <span className="form-text"><sup className="text-danger">*</sup>Required.</span>
                </div>
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveReminder} >Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>    
    </>
  );
}

export default ReminderAdd;
