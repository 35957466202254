import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import DatePicker from "react-datepicker"; // From https://reactdatepicker.com/
import MaskedInput from "react-text-mask";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./TransactionEdit.css";
import "react-datepicker/dist/react-datepicker.css";
import {  Timestamp } from "firebase/firestore";
import { getTransactionTemplate, getTransaction, updateTransaction, currencyMask, titleCase, dateString, dateMask, dateMaskPipe } from "./firebaseData";

var compareDisplayIfValues = {
  '===': function (x, y) { return x === y },
  '>': function (x, y) { 
    let xNum = parseInt(x.replace(/[^0-9\.-]+/g,""));
    return xNum > y 
  } 
}


function TransactionEdit(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [transaction, setTransaction] = useState('');
  const [transactionTemplate, setTransactionTemplate] = useState('');
  const [dates, setDates] = useState([]);
  const location = useLocation();
  const params = useParams();
  console.log("Displaying / Editing Transaction ID: ", params.transactionID); 
  /*
  const pathParts = document.location.pathname.split("/");

  let transactionID = '';
  if(pathParts.length === 4){
    transactionID = pathParts[3];
  }
  console.log("Editing Transaction ID: ", transactionID, props, location.state);
  */

  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    const versionInfo = await fetchVersionInfo();
    const transaction = await getTransaction(params.transactionID);
    console.log("Transaction: ", transaction);
    let templateID = transaction.templateID
    if (transaction.templateID === 'LVR2023'){
      templateID = "2gz2ALiqBwfXEvLbnYqm";
    }
    console.log("Fetching Template ID: ", templateID);
    const transactionTemplate = await getTransactionTemplate(templateID);
    console.log("Editing Transaction: ", transaction);
    console.log("Transaction Template: ", transactionTemplate);
    console.log("Transaction: ", transaction);

    setUserInfo(userDoc);
    setVersionID(versionInfo);
    setTransaction(transaction);
    setTransactionTemplate(transactionTemplate);

    let templateDates = {};
    transactionTemplate.fields.forEach((field) => {
      if(field.type === 'date'){
        if(field.name === 'accepted'){
          let dateValue = transaction.acceptDate.toDate();
          console.log(dateValue);
          templateDates[field.name] = dateValue;
        }
        if(field.name === 'coe'){
          let dateValue = transaction.closeDate.toDate();
          console.log(dateValue);
          templateDates[field.name] = dateValue;
        }
      }
    });
    console.log(templateDates);
    setDates(templateDates);

  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if (!params.transactionID) return navigate("/");

    fetchData();

  }, [user, loading]);


  const validateForm = ((formJson, transactionTemplate, transaction) =>{
    let isValid = true;

    console.log(formJson);
    console.log(transactionTemplate);
    console.log(transaction);
    console.log('HERE...');

    console.log('Checking Fields...');
    transactionTemplate.fields.forEach((field) =>{
      let formItem = formJson[field.name];
      console.log('Field Name: ', field.name, ' Required: ', field.required, ' Type: ', field.type, ' Value: ', formItem);

      var formElement = document.getElementById(field.name);  

      if(!formItem){
        if(field.required){
          isValid = false;
          formElement.classList.remove('is-valid');
          formElement.classList.add('is-invalid');
          console.log('Missing required value...');
        }
      } else {
        //Continue validation...
        if(field.type === 'text'){
          formElement.classList.remove('is-invalid');
          formElement.classList.add('is-valid');
          //console.log('Required text value found...');
          transaction[field.name] = formItem;
        }

        if(field.type === 'date'){
          let valueAsDate = new Date(formItem);
          if(isNaN(valueAsDate)){
            isValid = false;
            formElement.classList.remove('is-valid');
            formElement.classList.add('is-invalid');
            console.log('Date value is not a valid date...');
          } else {
            formElement.classList.remove('is-invalid');
            formElement.classList.add('is-valid');
            //console.log('Required date value found...');
            //Store date...
            transaction[field.name] = valueAsDate;
            
            if(field.name === 'accepted'){
              transaction.acceptDate = valueAsDate;
            }
            if(field.name === 'coe'){
              transaction.closeDate = valueAsDate;
            }
            
          }
        }

        if(field.type === 'number'){
          let valueAsNumber = parseInt(formItem.replace(/[^0-9\.-]+/g,""));
          if(isNaN(valueAsNumber)){
            isValid = false;
            formElement.classList.remove('is-valid');
            formElement.classList.add('is-invalid');
            console.log('Number value is not a valid number...');
          } else {
            formElement.classList.remove('is-invalid');
            formElement.classList.add('is-valid');
            //console.log('Required number value found...');
            //Store number...
            transaction[field.name] = valueAsNumber;
          }
        }
      }
    });


    if(isValid){
      //return the processed data for storage...
      console.log(transaction);
      return(transaction);
    }
    console.log('Invalid form data...');
    return(false);
  });

  const handleEditTransactionSubmit = (async (event) =>{
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    let validTransaction = validateForm(formJson, transactionTemplate, transaction);
    if(validTransaction){
      validTransaction.status = formJson.status;
      console.log('Updating Transaction: ', validTransaction);
      await updateTransaction(user, userInfo.name, transaction.id, validTransaction);

      if(location && location.state && location.state.list === "team"){
        return navigate("/teamTransactions");
      } else {
        return navigate("/dashboard");
      }
    }

  }); 

  const handleDateChange = (date, fieldName) => {
    //console.log("Handle Date Change: ", fieldName, date);
    //console.log("Original:" , dates);
    let newDates = structuredClone(dates);
    //console.log("Clone:" , newDates);
    newDates[fieldName] = date;
    //console.log("Updated: ", newDates);
    setDates(newDates);
  }


  const handleChange = (async (event) => {

    const form = document.getElementById("editTransactionForm");
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log('VALUE Changed... (Not used)');

  });


  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Transactions", link: "/"}, {title: "Edit Transaction", link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="transactions" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

                <div className="card-header border-bottom">
                    <h1>Edit Transaction</h1>
                    <h2 className="h6 text-muted">Please update the relevant contractual terms for this transaction...</h2>
                </div>

                { (!transaction || !transactionTemplate) && 
                 <div className="card-body">
                    <div className="text-center">
                        <h2>Loading...</h2>
                          <Rings
                          height="180"
                          width="180"
                          color="#0000ff"
                          radius="6"
                          wrapperStyle={{display:"block"}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="rings-loading"
                        />
                    </div>
                  </div>
                }
                { transaction && 
                <div className="card-body">

                  <form id="editTransactionForm" method="post" onSubmit={handleEditTransactionSubmit} className="needs-validation" noValidate>
                    <div className="row row-cols-2">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="editStatus" className="form-label">Status</label>
                          <select id="editStatus" name="status" className="form-select" defaultValue={transaction.status} >
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                            <option value="cancelled">Cancelled</option>
                          </select>
                          <span className="form-text"><sup className="text-danger">*</sup>Required.</span>
                        </div>
                      </div>

                      { transactionTemplate.fields.map(field => (            
                      <div className="col" key={field.name}>
                        <div className="mb-3">
                          <label htmlFor={field.name} className="form-label">{field.label}</label>


                          { field.type === 'choice' && field.choices &&
                            <select id={field.name} name={field.name} className="form-select" onChange={handleChange} defaultValue={transaction[field.name]} > 
                              {field.choices.map(choice => (
                                <option key={choice} value={choice}>{titleCase(choice)}</option>
                              ))}
                            </select>
                          }

                          { field.type === 'text' &&
                          <input type="text" id={field.name} name={field.name} className="form-control" placeholder={field.helperText} aria-label={field.helperText} required={field.required} defaultValue={transaction[field.name]} onChange={handleChange} />
                          }

                          { field.type === 'date' && 
                          <DatePicker className="form-control" selected={dates[field.name]} id={field.name} name={field.name} required={field.required} todayButton="Today" showPopperArrow={false} onChange={(date) => handleDateChange(date, field.name)} />
                          }

                          { field.type === 'number' && 
                          <MaskedInput mask={currencyMask} className="form-control" id={field.name} name={field.name} placeholder={field.helperText} required={field.required} defaultValue={transaction[field.name]} onChange={handleChange} />  
                          }

                          <span className="form-text"><sup className="text-danger">{field.required ? '*': ''}</sup> {field.required ? 'Required.' : ''} {field.helperText}.</span>
                        </div>
                      </div>

                      ))}

                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <a href="/dashboard" className="btn btn-secondary">Cancel</a>
                      <button className="btn btn-primary ms-2">Save</button>
                    </div>
                  </form>
                </div>
              }
              </div>
            </div> 
          </div>
        </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default TransactionEdit;