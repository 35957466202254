import { addDays, addBusinessDays, subDays, format, isSameDay, isBefore } from "date-fns";
import { db } from "./firebaseAuth";
import { doc, deleteDoc, getDoc, query, getDocs, collection, where, addDoc, setDoc, Timestamp, serverTimestamp, orderBy, updateDoc } from "firebase/firestore";
import { createNumberMask, createAutoCorrectedDatePipe } from "text-mask-addons";

const validateEmail = (emailAddress) =>  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress))
    {
      return (true)
    }
      return (false)
  }

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const dateString = (date) =>{
  //return(((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
  return(format(date, 'MM/dd/yyyy'));
}

const titleCase = (str) => {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

const currencyMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const currencyMask = createNumberMask(currencyMaskOptions);
const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const dateMaskPipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const getUserByID = async (uid) => {
  var userRef = doc(db, 'users', uid);
  var userSnap = await getDoc(userRef);
  var user = userSnap.data();  
  return(user);
}

const sortRemindersByDate = (reminderA, reminderB) => {
  return(reminderA.dueDate - reminderB.dueDate);
}

const sortRemindersByOrder = (reminderA, reminderB) => {
  return(reminderA.order - reminderB.order);
}

const getTransactionTemplates = async () =>{
  const q = query(collection(db, "transactionTemplates"), orderBy("order"));
  const querySnap = await getDocs(q);
  let results = []
  for(const transactionTemplate of querySnap.docs) {
    const docID = transactionTemplate.id;
    const transactionTemplateData = transactionTemplate.data();
    transactionTemplateData.id = docID;
    results.push(transactionTemplateData);
  }
  console.log('Transaction Templates: ', results)
  return(results);
}

const getTeams = async () => {
  const querySnap = await getDocs(collection(db, "teams"));
  let results = []
  for(const team of querySnap.docs) {
    const docID = team.id;
    const teamData = team.data();
    teamData.id = docID;
    results.push(teamData);
  }
  console.log('Teams: ', results)
  return(results);
}

const getTeamsForOwner = async (ownerID) => {

  const q = query(collection(db, "teams"), where("owner", "==", ownerID));
  const querySnap = await getDocs(q);

  let results = []
  for(const team of querySnap.docs) {
    const docID = team.id;
    const teamData = team.data();
    teamData.id = docID;
    results.push(teamData);
  }
  console.log('Teams: ', results)
  return(results);
}



const getTeam = async (teamID) => {
  const teamRef = doc(db, "teams", teamID);
  const teamSnap = await getDoc(teamRef);  
  const teamData = teamSnap.data();
  teamData.id = teamSnap.id;
  return(teamData);
}

const createTeam = async (team) => {
  console.log("Creating Team: ", team);
  const teamRef = doc(db, "teams", team.id);
  return await setDoc(teamRef, team);

}

const updateTeam = async (team) => {
  console.log("Updating Team: ", team);
  const teamRef = doc(db, "teams", team.id);
  return await updateDoc(teamRef, team);
}

const getTransactionTemplate = async (templateID) =>{
  const transactionTemplateRef = doc(db, "transactionTemplates", templateID);
  const transactionTemplateSnap = await getDoc(transactionTemplateRef);  
  const transactionTemplateData = transactionTemplateSnap.data();
  transactionTemplateData.id = transactionTemplateSnap.id;
  for (let i=0; i < transactionTemplateData.fields.length; i++) {
    transactionTemplateData.fields[i].id = transactionTemplateData.fields[i].name;
  }
  for (let i=0; i < transactionTemplateData.reminders.length; i++) {
    transactionTemplateData.reminders[i].id = transactionTemplateData.reminders[i].name;
  }
  return(transactionTemplateData);
}

const updateTransactionTemplate = async (template) => {
  console.log("Updating Template: ", template);
  const templateRef = doc(db, "transactionTemplates", template.id);
  return await updateDoc(templateRef, template);
}

const addTransactionTemplate = async (template) => {
  console.log("Adding Template: ", template);
  return await addDoc(collection(db, "transactionTemplates"), template);
}


const addTransaction = async (user, userName, transaction) => {

  console.log("Firebase Data addTransaction:", transaction);
  transaction["status"] = "open";
  transaction["createDate"] = serverTimestamp();
  transaction["createdBy"] = { uid: user.uid, name: userName, email: user.email};
  transaction["lastUpdatedDate"] = serverTimestamp();
  transaction["owner"] = user.uid;
  //transaction["sides"] = ["buy"];
  console.log(transaction);
  if (transaction.developer){
    transaction.side = "Buy (New)";
  }
  transaction["title"] = [transaction.clientName, "-", titleCase(transaction.side), transaction.address].join(' '); 

  let reminders = transaction.reminders;
  delete transaction.reminders;
  delete transaction.accepted;
  delete transaction.coe;

  console.log(transaction);
  console.log(reminders);

  const transactionRef = await addDoc(collection(db, "transactions"), transaction);

  reminders.forEach((reminder) => {
    if(reminder.title){
      addDoc(collection(db, "transactions", transactionRef.id, "reminders"), reminder);    
    }
  });

}
/*
const addDefaultTransaction = async (user, userName, mls, title, sides, terms) => {

  console.log('Start Add Default TX. ', title, mls );

  let acceptedDate = Timestamp.fromDate(terms.acceptDate);
  let coeDate = Timestamp.fromDate(terms.closeDate);
  let contractPrice = terms.contractPrice;
  let emdAmount = terms.emdAmount;

  let emdDepositDate = Timestamp.fromDate(addBusinessDays(terms.acceptDate, terms.emdDepositDays));

  let additionalDepositDate = null;
  if(terms.additionalDepositDate){
    additionalDepositDate = Timestamp.fromDate(terms.additionalDepositDate); 
  }

  let loanAppDate = null;
  if(terms.loanAppDays){
    loanAppDate = Timestamp.fromDate(addBusinessDays(terms.acceptDate, terms.loanAppDays)); 
  }

  let appraisalDate = null;
  if(terms.appraisalDays){
    appraisalDate = Timestamp.fromDate(addDays(terms.acceptDate, terms.appraisalDays)); 
  }

  let loanContingencyDate = null;
  if(terms.loanContingencyDays){
    loanContingencyDate = Timestamp.fromDate(addDays(terms.acceptDate, terms.loanContingencyDays)); 
  }

  let cashPOFDate = null;
  if(terms.cashPOFDays){
    cashPOFDate = Timestamp.fromDate(addBusinessDays(terms.acceptDate, terms.cashPOFDays)); 
  }

  let openEscrowDate = Timestamp.fromDate(addBusinessDays(terms.acceptDate, 1)); 

  let dueDiligenceDate = null;
  if(terms.dueDiligenceDays){
    dueDiligenceDate = Timestamp.fromDate(addDays(terms.acceptDate, terms.dueDiligenceDays)); 
  }

  let scheduleInspectionDate = null;
  if(terms.scheduleInspectionDays){
    scheduleInspectionDate = Timestamp.fromDate(addDays(terms.acceptDate, terms.scheduleInspectionDays)); 
  }

  let walkThroughDate = Timestamp.fromDate(subDays(terms.closeDate, 1)); 
  if(terms.walkThrough){
    walkThroughDate = Timestamp.fromDate(subDays(terms.closeDate, terms.walkThrough)); 
  }
 
  console.log('Walk TimeStamp: ', walkThroughDate);

  let transaction = {
    acceptDate: acceptedDate,
    closeDate: coeDate,
    status: 'open',
    createDate: serverTimestamp(),
    createdBy: { uid: user.uid, name: userName, email: user.email},
    lastUpdatedDate: serverTimestamp(),
    mls: mls,
    owner: user.uid,
    sides: sides,
    title: title,
    contractPrice: contractPrice,
    emdAmount: emdAmount
  }

  if (terms.sellerCredit){
    transaction.sellerCredit = terms.sellerCredit; 
  }
  if (terms.homeProtectionPlanAmount){
    transaction.homeProtectionPlanAmount = terms.homeProtectionPlanAmount; 
  }
  if (terms.homeProtectionPlanPaidBy){
    transaction.homeProtectionPlanPaidBy = terms.homeProtectionPlanPaidBy; 
  }

  const transactionRef = await addDoc(collection(db, "transactions"), transaction);
  
  if(openEscrowDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Open Escrow (1 Biz Day)',
      completed: false,
      dueDate: openEscrowDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }

  if(emdDepositDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Deposit EMD (' + terms.emdDepositDays + ' Biz Days)',
      completed: false,
      dueDate: emdDepositDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }

  if(additionalDepositDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Additional Deposit',
      completed: false,
      dueDate: additionalDepositDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }

  if(loanAppDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Loan Application (' + terms.loanAppDays + ' Biz Days)',
      completed: false,
      dueDate: loanAppDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  } 

  if(cashPOFDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Provide Cash POF (' + terms.cashPOFDays + ' Cal Days)',
      completed: false,
      dueDate: cashPOFDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  } 

  if(dueDiligenceDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Due Diligence (' + terms.dueDiligenceDays + ' Cal Days)',
      completed: false,
      dueDate: dueDiligenceDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }

  if(scheduleInspectionDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Schedule Inspection (' + terms.scheduleInspectionDays + ' Cal Days)',
      completed: false,
      dueDate: scheduleInspectionDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }

  if(appraisalDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Appraisal (' + terms.appraisalDays + ' Cal Days)',
      completed: false,
      dueDate: appraisalDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }

  if(loanContingencyDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Loan Contingency (' + terms.loanContingencyDays + ' Cal Days)',
      completed: false,
      dueDate: loanContingencyDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }

  
  if(walkThroughDate){
    addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
      title: 'Walk Through Date',
      completed: false,
      dueDate: walkThroughDate,
      reminderEmails: [user.email],
      reminderFrequency: 'dailyUntilComplete'  
    });  
  }  

  let sellerUtilitiesOnDate = Timestamp.fromDate(addBusinessDays(terms.acceptDate, 2)); 
  addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
    title: 'Seller Turn Utilities On (2 Biz Days)',
    completed: false,
    dueDate: sellerUtilitiesOnDate,
    reminderEmails: [user.email],
    reminderFrequency: 'dailyUntilComplete'  
  });  

  let sellerOrderCICPackage = Timestamp.fromDate(addBusinessDays(terms.acceptDate, 2)); 
  addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
    title: 'Seller Order CIC Package (2 Biz Days)',
    completed: false,
    dueDate: sellerOrderCICPackage,
    reminderEmails: [user.email],
    reminderFrequency: 'dailyUntilComplete'  
  });  

  let sellerProvideDisclosures = Timestamp.fromDate(addDays(terms.acceptDate, 5)); 
  addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
    title: 'Seller Provide Disclosures (5 Cal Days)',
    completed: false,
    dueDate: sellerProvideDisclosures,
    reminderEmails: [user.email],
    reminderFrequency: 'dailyUntilComplete'  
  });  

  let ptrDueDate = Timestamp.fromDate(addBusinessDays(terms.acceptDate, 10)); 
  addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
    title: 'Prelim Title Report Due (10 Biz Days)',
    completed: false,
    dueDate: ptrDueDate,
    reminderEmails: [user.email],
    reminderFrequency: 'dailyUntilComplete'  
  });  

  let buyerMustReceiveCICPackage = Timestamp.fromDate(addDays(terms.acceptDate, 15)); 
  addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
    title: 'Buyer Receive CIC Package (15 Cal Days)',
    completed: false,
    dueDate: buyerMustReceiveCICPackage,
    reminderEmails: [user.email],
    reminderFrequency: 'dailyUntilComplete'  
  });  

  let buyerTurnUtilitiesOn = Timestamp.fromDate(subDays(terms.closeDate, 7)); 
  addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
    title: 'Buyer Turn On Utilities',
    completed: false,
    dueDate: buyerTurnUtilitiesOn,
    reminderEmails: [user.email],
    reminderFrequency: 'dailyUntilComplete'  
  });  


  let orderHomeWarrantyDate = Timestamp.fromDate(subDays(terms.closeDate, 5)); 
  addDoc(collection(db, "transactions", transactionRef.id, "reminders"), {
    title: 'Order Home Warranty',
    completed: false,
    dueDate: orderHomeWarrantyDate,
    reminderEmails: [user.email],
    reminderFrequency: 'dailyUntilComplete'  
  });  
  
  
  console.log("Transaction written with ID: ", transactionRef.id);

}
*/

const sendTransactionEmail = async ( emailInfo ) => {
  console.log("sendTransactionEmail Start");
  console.log("Transaction ID: ", emailInfo.emailTransactionID);
  console.log("Recipient List: ", emailInfo.emailList);
  console.log("Message: ", emailInfo.emailTransactionMessage);
  var transaction = await getTransaction(emailInfo.emailTransactionID);
  transaction.emailTransactionMessage = emailInfo.emailTransactionMessage.split('\n').join('<br />');;

  let user = await getUserByID(transaction.owner);

  transaction["senderName"] = user.name;
  transaction["email"] = user.email;
  if(user.company){
    transaction["company"] = user.company;  
  }
  if(user.mobile){
    transaction["mobile"] = user.mobile;  
  }
  if(user.jobTitle){
    transaction["jobTitle"] = user.jobTitle;  
  }
  if(user.office){
    transaction["office"] = user.office;  
  }
  if(user.website){
    transaction["website"] = user.website;  
  }
  if(user.officeAddress){
    transaction["officeAddress"] = user.officeAddress.replace(/\n/g, ", ");  
  }

  let now = new Date();

  transaction.closeDateStyle = 'color: #000;';
  if(isBefore(transaction.closeDate.toDate(), now)){
    transaction.closeDateStyle = 'color: #ff0000; font-weight: bold;';
  }

  transaction.acceptDate = dateString(transaction.acceptDate.toDate());
  transaction.closeDate = dateString(transaction.closeDate.toDate());
  transaction.contractPrice = currencyFormatter.format(transaction.contractPrice);

  if(transaction.emdAmount){
    transaction.emdAmount = currencyFormatter.format(transaction.emdAmount);
  }

  if(transaction.homeProtectionPlanAmount){
    transaction.homeProtectionPlanAmount = currencyFormatter.format(transaction.homeProtectionPlanAmount);
  }

  if(transaction.sellerCredit){
    transaction.sellerCredit = currencyFormatter.format(transaction.sellerCredit);
  }

  for(let i = 0; i < transaction.reminders.length; i++){

    if(isSameDay(transaction.reminders[i].dueDate.toDate(), now)){
      //console.log('Same Day!');
      transaction.reminders[i].rowStyle = 'color: #ffae00; font-weight: bold;';
    } else {
      //console.log('Not Same Day...');
      if(isBefore(transaction.reminders[i].dueDate.toDate(), now)){
        transaction.reminders[i].rowStyle = 'color: #ff0000; font-weight: bold;';
      } else {
        transaction.reminders[i].rowStyle = 'color: #000;';
      }
    }

    if(transaction.reminders[i].dueDate){
      transaction.reminders[i].dueDate = dateString(transaction.reminders[i].dueDate.toDate());
    }
  }
 
  console.log("Transaction: ", transaction);

  let templateName = "oneTimeTransactionUpdate";
  if(transaction.companyLogo){
    templateName = "oneTimeTransactionUpdateBranded";
  }

  let mailObject = {
    to: emailInfo.emailList,
    replyTo: transaction.createdBy.email,
    template: {
      name: templateName,
      data: transaction
    },
  }

  if(transaction.owner !== '0kRkdr3WuvOGDNPV8gBNZVgWSvv1'){
    mailObject["bcc"] = "michael@changevegas.com";
  }
  return await addDoc(collection(db, "mail"), mailObject);
}

const updateTransaction = async(user, userName, transactionID, transaction) =>{
  console.log('Edit Transaction Start!');
  console.log("User Name: ", userName);
  console.log("Transaction ID: ", transactionID);

  transaction["title"] = [transaction.clientName, "-", titleCase(transaction.side), transaction.address].join(' '); 

  
  //Convert Dates to Firebase Timestamps
  if(transaction.acceptDate){
    transaction.acceptDate = Timestamp.fromDate(transaction.acceptDate);
  }
  if(transaction.closeDate){
    transaction.closeDate = Timestamp.fromDate(transaction.closeDate);
  }
  
  console.log(transaction);
  const transactionRef = doc(db, "transactions", transactionID);
  return await updateDoc(transactionRef, transaction);
}

const closeTransaction = async(user, userName, transactionID) =>{
  const transactionRef = doc(db, "transactions", transactionID);
  return await updateDoc(transactionRef, { status: 'closed' });
}

const updateTransactionContacts = async(transactionID, emailList) =>{
  console.log('Update Transaction Contact Start!');
  console.log("Transaction ID: ", transactionID);
  console.log("Email List: ", emailList);

  const transactionRef = doc(db, "transactions", transactionID);
  return await updateDoc(transactionRef, { contactList: emailList });
}

const deleteTransaction = async (transaction) => {
  console.log("Deleting Transaction: ", transaction);

  const s = query(collection(db, "transactions", transaction.id, "reminders"));
  const reminders = await getDocs(s);
  for(const reminder of reminders.docs) { 
    deleteDoc(doc(db, "transactions", transaction.id, "reminders", reminder.id))
  }

  return await deleteDoc(doc(db, "transactions", transaction.id));
}

const getTransaction = async (transactionID) => {
  const transactionRef = doc(db, "transactions", transactionID);
  const transactionSnap = await getDoc(transactionRef);  
  const transactionData = transactionSnap.data();
  transactionData.id = transactionSnap.id;

  const s = query(collection(db, "transactions", transactionSnap.id, "reminders"));
  const reminders = await getDocs(s);
  let reminderList = [];
  for(const reminder of reminders.docs) { 
    const reminderID = reminder.id;
    const reminderData = reminder.data();
    reminderData.id = reminderID;
    reminderData.parentID = transactionSnap.id;
    reminderList.push(reminderData);
  }
  transactionData.reminders = reminderList.sort(sortRemindersByDate);  
  return(transactionData);
}

const getTransactionsForUser = async (user, status = "open") => {

  const q = query(collection(db, "transactions"), where("status", "==", status), where("owner", "==", user.uid), orderBy("closeDate"));
  const transactions = await getDocs(q);
  let results = []
  for(const transaction of transactions.docs) {
    const docID = transaction.id;
    const docData = transaction.data();
    docData.id = docID;

    //const s = query(collection(db, "transactions/" + transaction.id + "/reminders"));
    const s = query(collection(db, "transactions", transaction.id, "reminders"));
    const reminders = await getDocs(s);
    let reminderList = [];
    let now = new Date();
    for(const reminder of reminders.docs) { 
      const reminderID = reminder.id;
      const reminderData = reminder.data();
      reminderData.id = reminderID;
      if(reminderData.completed === false){
        if(docData.reminderType === "milestone completion"){
          reminderData.className = 'reminderNormal';
        } else {
          //console.log('Due Date: ', reminderData.dueDate.toDate())
          if(isSameDay(reminderData.dueDate.toDate(), now)){
            //console.log('Same Day!');
            reminderData.className = 'reminderDueToday';
          } else {
            //console.log('Not Same Day...');
            if(isBefore(reminderData.dueDate.toDate(), now)){
              reminderData.className = 'reminderPastDue';
            } else {
              reminderData.className = 'reminderNormal';
            }
          }
        }
      } else {
        reminderData.className = 'reminderCompleted';
      }
      reminderData.parentID = transaction.id;
      reminderList.push(reminderData);
    }
    if(docData.reminderType === "milestone completion"){
      docData.reminders = reminderList.sort(sortRemindersByOrder);  
    } else {
      docData.reminders = reminderList.sort(sortRemindersByDate);  
    }
    results.push(docData);
  }
  console.log(results);
  return results;
}

const getTransactionsForUserList = async (userList) => {

  const q = query(collection(db, "transactions"), where("status", "==", "open"), where("owner", "in", userList), orderBy("closeDate"));
  const transactions = await getDocs(q);
  let results = []
  for(const transaction of transactions.docs) {
    const docID = transaction.id;
    const docData = transaction.data();
    docData.id = docID;

    //const s = query(collection(db, "transactions/" + transaction.id + "/reminders"));
    const s = query(collection(db, "transactions", transaction.id, "reminders"));
    const reminders = await getDocs(s);
    let reminderList = [];
    let now = new Date();
    for(const reminder of reminders.docs) { 
      const reminderID = reminder.id;
      const reminderData = reminder.data();
      reminderData.id = reminderID;
      if(reminderData.completed === false){
        if(docData.reminderType === "milestone completion"){
          reminderData.className = 'reminderNormal';
        } else {
          //console.log('Due Date: ', reminderData.dueDate.toDate())
          if(isSameDay(reminderData.dueDate.toDate(), now)){
            //console.log('Same Day!');
            reminderData.className = 'reminderDueToday';
          } else {
            //console.log('Not Same Day...');
            if(isBefore(reminderData.dueDate.toDate(), now)){
              reminderData.className = 'reminderPastDue';
            } else {
              reminderData.className = 'reminderNormal';
            }
          }
        }
      } else {
        reminderData.className = 'reminderCompleted';
      }
      reminderData.parentID = transaction.id;
      reminderList.push(reminderData);
    }
    if(docData.reminderType === "milestone completion"){
      docData.reminders = reminderList.sort(sortRemindersByOrder);  
    } else {
      docData.reminders = reminderList.sort(sortRemindersByDate);  
    }
    results.push(docData);
  }
  console.log(results);
  return results;
}

const addReminder = async (reminder) => {
  return await addDoc(collection(db, "transactions", reminder.parentID, "reminders"), reminder);
}

const updateReminder = async (reminder) => {
  const reminderRef = doc(db, "transactions", reminder.parentID, "reminders", reminder.id);
  return await setDoc(reminderRef, reminder);
}

const deleteReminder = async (reminder) => {
  console.log("Deleting Reminder: ", reminder);
  return await deleteDoc(doc(db, "transactions", reminder.parentID, "reminders", reminder.id));
}

export {
  currencyFormatter,
  currencyMask,
  dateString,
  dateMask,
  dateMaskPipe,
  phoneMask,
  validateEmail,
  titleCase,
  getUserByID,
  getTransactionTemplates,
  getTransactionTemplate,
  addTransactionTemplate,
  updateTransactionTemplate,
  getTransaction,
  getTransactionsForUser,
  getTransactionsForUserList,
  addTransaction,
  //addDefaultTransaction,
  updateTransaction,
  closeTransaction,
  updateTransactionContacts,
  sendTransactionEmail,
  deleteTransaction,
  addReminder,
  updateReminder,
  deleteReminder,
  getTeams,
  getTeamsForOwner,
  getTeam,
  createTeam,
  updateTeam,
};
