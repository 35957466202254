import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, query, getDocs, collection, where, setDoc } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";

//Source: https://blog.logrocket.com/user-authentication-firebase-react-apps/

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYjH4ElhXs9iTfM61nb4ziW10dUvzbiEs",
  authDomain: "transactionminder.firebaseapp.com",
  databaseURL: "https://transactionminder-default-rtdb.firebaseio.com",
  projectId: "transactionminder",
  storageBucket: "transactionminder.appspot.com",
  messagingSenderId: "667258635438",
  appId: "1:667258635438:web:761a7aad6fbc03cf3f993f",
  measurementId: "G-SMHJRLLRXP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


const googleProvider = new GoogleAuthProvider();
//googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
//googleProvider.addScope('https://www.googleapis.com/auth/calendar.readonly');

const getUser = async (user) => {
  try {
    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();
    console.log(data);
    return data;
  } catch (err) {
    console.error(err);
  }
};

const updateUser = async (user) => {
  try {
    return await setDoc(doc(db, "users", user.uid), user);
  } catch (err) {
    console.error(err);
  }
  return(false);
};


const fetchVersionInfo = async () => {

  const metaRef = doc(db, "appinfo", "versionInfo");
  const metaSnap = await getDoc(metaRef);
  const metaDoc = metaSnap.data();
  console.log(metaDoc);
  return(metaDoc.versionID);   

};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      name,
      authProvider: "local",
      authorized: true,
      defaultTemplate: "LVR2023",
      email,
    });
    return(user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(res);
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    console.log(docs);
    if (docs.docs.length === 0) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        authorized: true,
        defaultTemplate: "LVR2023",
        photoURL: user.photoURL,
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};


export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getUser,
  updateUser,
  fetchVersionInfo,
};
