import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getUser, logout } from "./firebaseAuth";
import "./SideBar.css";
import { getTeamsForOwner } from "./firebaseData";

function SideBar(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [directReports, setDirectReports] = useState([]);

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    let teams = await getTeamsForOwner(userDoc.uid)
    let directReportList = []
    teams.forEach(team => {
      directReportList = directReportList.concat(team.accepted);
    });
    setDirectReports(directReportList);
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    console.log('User UID: ', user.uid);

    fetchData();
    //console.log(window);

  }, [user, loading]);


  return (
    <div className="navbar-expand-lg navbar-light sticky-top">
      <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
        <div className="card flex-grow-1 mb-5">
          <div className="card-body">
            <div className="text-center mb-5">
              {props.userInfo &&
                <>

                  {(props.user && props.user.photoURL) &&
                  <div className="avatar avatar-xxl avatar-circle mb-3">
                    <img className="avatar-img" referrerPolicy="no-referrer" src={props.user.photoURL} alt="" />
                  </div>
                  }

                  <h4 className="card-title mb-0">{props.userInfo.name}</h4>
                  <p className="card-text small">{props.user ? props.user.email : ''}
                  { (props.userInfo.authorized && props.userInfo.authorized === true) 
                      ? <><br /><span className="small text-success"><i className="bi bi-person-check me-1"></i>Authorized</span></>
                      : <><br /><span className="small text-danger"><i className="bi bi-person-x me-1"></i>Not currently authorized</span></>
                    }
                  </p>
                </>
              }
            </div>

            <span className="text-cap">Transactions</span>

            <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
              <li className="nav-item">
                <a className={ props.activeNav === 'transactions' ? "nav-link active" : "nav-link"} href="/dashboard">
                <i className="bi bi-calendar2-check nav-icon"></i> Open Transactions
                </a>
              </li>
              <li className="nav-item">
                <a className={ props.activeNav === 'closedTransactions' ? "nav-link active" : "nav-link"} href="/closedTransactions">
                <i className="bi bi-calendar2-check nav-icon"></i> Closed Transactions
                </a>
              </li>
              {directReports &&
              <li className="nav-item">
                <a className={ props.activeNav === 'directReportsTransactions' ? "nav-link active" : "nav-link"} href="/teamTransactions">
                  <i className="bi bi-people nav-icon"></i> Team Transactions
                </a>
              </li>
              }
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                <i className="bi bi-person-rolodex nav-icon"></i> Contacts
                </a>
              </li>
              <li className="nav-item">
                <a className={ props.activeNav === 'templates' ? "nav-link active" : "nav-link"} href="/templates">
                  <i className="bi-heart nav-icon"></i> Templates
                </a>
              </li>
            </ul>

            <span className="text-cap">Account</span>

            <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
              <li className="nav-item">
                <a className={ props.activeNav === 'accountPreferences' ? "nav-link active" : "nav-link"}  href="/account/preferences">
                  <i className="bi-sliders nav-icon"></i> Preferences
                </a>
              </li>
              <li className="nav-item">
                <a className={ props.activeNav === 'accountPersonalInfo' ? "nav-link active" : "nav-link"}  href="/account/personalInfo">
                  <i className="bi-person-badge nav-icon"></i> Personal Info
                </a>
              </li>
              <li className="nav-item">
                <a className={ props.activeNav === 'teams' ? "nav-link active" : "nav-link"}   href="/teams">
                  <i className="bi-people nav-icon"></i> Teams
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  <i className="bi-shield-shaded nav-icon"></i> Security
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  <i className="bi-bell nav-icon"></i> Notifications
                </a>
              </li>
            </ul>
            
            <span className="text-cap">Billing</span>

            <ul className="nav nav-sm nav-tabs nav-vertical">
              <li className="nav-item">
                <a  className={ props.activeNav === 'billingPayments' ? "nav-link active" : "nav-link"} href="/billing/payments">
                  <i className="bi-credit-card nav-icon"></i> Payments
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  <i className="bi-geo-alt nav-icon"></i> Address
                </a>
              </li>
            </ul>

            <div className="d-lg-none">
              <div className="dropdown-divider"></div>

              <ul className="nav nav-sm nav-tabs nav-vertical">
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={logout}>
                    <i className="bi-box-arrow-right nav-icon"></i> Log out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
);
}

export default SideBar;
