import React, {useState} from "react";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker"; // From https://reactdatepicker.com/
import { Timestamp } from "firebase/firestore";
import { dateMask, dateMaskPipe, updateReminder } from "./firebaseData";
import "./ReminderEdit.css";
import "react-datepicker/dist/react-datepicker.css";

function ReminderEdit(props) {

  const [dueDate, setDueDate] = useState(new Date());

  const handleSaveReminder = (async (event) => { 
    event.preventDefault();

    console.log('Edit Reminder - Save Event');

    const form = document.getElementById('editReminderForm');
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);


    //Validate
    var valid = true;
    var titleElement = document.getElementById("editReminderTitle");
    var dueDateElement = document.getElementById("editReminderDueDate");

    if(!formJson.reminderTitle){
      valid = false;
      titleElement.classList.remove('is-valid');
      titleElement.classList.add('is-invalid');
    } else {
      titleElement.classList.remove('is-invalid');
      titleElement.classList.add('is-valid');
    }

    let dueDate = new Date(formJson.reminderDueDate);
    if(isNaN(dueDate)){
      valid = false;
      dueDateElement.classList.remove('is-valid');
      dueDateElement.classList.add('is-invalid');
    } else {
      dueDateElement.classList.remove('is-invalid');
      dueDateElement.classList.add('is-valid');
    }

    if(!valid){
      form.classList.add('hasValidation');
      return(false);
    } else {
      form.classList.remove('hasValidation');
    }

    //If Valid...
    let reminder = JSON.parse(formJson.editReminderObject);

    reminder.title = formJson.reminderTitle;
    reminder.dueDate = Timestamp.fromDate(new Date(formJson.reminderDueDate));
    //setDueDate(new Date(formJson.reminderDueDate));

    //console.log(reminder);
    
    //await updateReminder(reminder);

    //Manually hide modal...
    document.getElementById('modalEditReminder').classList.remove('show');
    document.body.classList.remove('modal-open');
    document.getElementsByClassName('modal-backdrop')[0].remove();
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('padding-right');

    props.callback(reminder);

    //window.location.reload();
  });


  return (
    <>
      <div className="modal fade" id="modalEditReminder" tabIndex="-1" aria-labelledby="modalEditReminderLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form id="editReminderForm" method="post" onSubmit={handleSaveReminder} className="needs-validation" noValidate>
              <div className="modal-header">
                <h3 className="modal-title" id="modalEditReminderLabel">Edit Reminder</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <input type="hidden" id="editReminderObject" name="editReminderObject" />
                <div className="mb-3">
                  <label htmlFor="editReminderTitle" className="form-label">Reminder Title</label>
                  <input type="text" id="editReminderTitle" name="reminderTitle" className="form-control" placeholder="Reminder Title" aria-label="Reminder Title" required/>
                  <span className="form-text"><sup className="text-danger">*</sup>Required.</span>
                </div>

                <div className="mb-3">
                  <label htmlFor="editReminderDueDate" className="form-label">Reminder Due Date</label>
                  <DatePicker className="form-control" selected={dueDate} id="editReminderDueDate" name="reminderDueDate" required onChange={(date) => setDueDate(date)} todayButton="Today" showPopperArrow={false} />
                  { false && 
                  <MaskedInput mask={dateMask} pipe={dateMaskPipe} className="form-control" id="editReminderDueDate" name="reminderDueDate" required />  
                  }
                  <span className="form-text"><sup className="text-danger">*</sup>Required.</span>
                </div>
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveReminder} >Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>    
    </>
  );
}

export default ReminderEdit;
