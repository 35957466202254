import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, updateUser } from "./firebaseAuth";
import MaskedInput from "react-text-mask";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./BillingPayments.css";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { phoneMask } from "./firebaseData";
import { FileUploader } from "react-drag-drop-files";
import { applyActionCode } from "firebase/auth";



function BillingPayments(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [applePay, setApplePay] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    document.title ="Transaction Minder: Billing Account Information";

    async function fetchData(){
      const userDoc = await getUser(user);
      const versionInfo = await fetchVersionInfo();
      setUserInfo(userDoc);
      setVersionID(versionInfo);

      console.log(window);
      if (window.ApplePaySession) {
        // The Apple Pay JS API is available.
        console.log('ApplePay: YES!')
        setApplePay(true);
      } else {
        console.log('ApplePay: NO!')
      }
    
    }
    fetchData();
  }, [user, loading]);


  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Billing", link: ""}, {title: "Payments", link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="billingPayments" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !userInfo && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { userInfo && 
                <>
                <div className="card-header border-bottom">
                <h1>Billing Payments</h1>
                <h2 className="h6 text-muted">
                  Please update your payment account as needed.<br />
                </h2>
                </div>


                <div className="card-body">
                  <p>Coming soon...</p>
                </div>


                
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Billing info updated...</p>                    
                </div>
            </div>
          </div>
      )}
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default BillingPayments;