import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "./firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import logo from "./logo.800x200.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      console.log('LOADING!');
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);
  
  return (

    <div className="mt-10">
      <div className="container">
        <header className="text-center mb-5">
          <a href="/"><img className="img-fluid" style={{ width: '400px' }} src={logo} alt="Transaction Minder" /></a>
        </header>
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                  />

                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
                <div className="mb-3 text-center">
                  <button className="btn btn-dark mb-2" onClick={() => logInWithEmailAndPassword(email, password)}>Login</button>
                  <button className="btn btn-primary mb-2 ms-2" onClick={signInWithGoogle}><i className="bi bi-google me-2"></i>Login with Google</button>
                </div>
                <div>
                  <Link to="/reset">Forgot Password</Link>
                </div>
                <div>
                  Don't have an account? <Link to="/register">Register</Link> now.
                </div>
              </div>
            </div>
        </div>
        </div>

      </div>
    </div>
  );
}
export default Login;