import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, updateUser } from "./firebaseAuth";
import MaskedInput from "react-text-mask";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./AccountPersonalInfo.css";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { phoneMask } from "./firebaseData";
import { FileUploader } from "react-drag-drop-files";



function AccountPersonalInfo(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [uploadFile, setUploadFile] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    document.title ="Transaction Minder: Account Personal Information";

    async function fetchData(){
      const userDoc = await getUser(user);
      const versionInfo = await fetchVersionInfo();
      setUserInfo(userDoc);
      setVersionID(versionInfo);
    }
    fetchData();
  }, [user, loading]);

  /*

Jason MacDonald
Branch Manager | HighTechLending, Inc.
NMLS #1589741
1635 Village Center Circle
Suite #120
Las Vegas, NV 89134
jmacdonald@hightechlending.com
lasvegas.hightechlending.com
(702) 996-3101 Office
(702) 491-2583 Cell
(702) 952-0858 Fax


  */

  const handleSave = (async (event) =>{
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    if(formJson.name){
      userInfo["name"] = formJson.name;
    }
    if(formJson.company){
      userInfo["company"] = formJson.company;
    }
    if(formJson.website){
      userInfo["website"] = formJson.website;
    }
    if(formJson.jobTitle){
      userInfo["jobTitle"] = formJson.jobTitle;
    }
  
    if(formJson.license){
      userInfo["license"] = formJson.license;
    }
    if(formJson.mobile){
      userInfo["mobile"] = formJson.mobile;
    }
    if(formJson.office){
      userInfo["office"] = formJson.office;
    }
    if(formJson.officeAddress){
      userInfo["officeAddress"] = formJson.officeAddress;
    }

    /*
    if(uploadFile){
      await handleUploadFile();
    }
    */

    let isValid = true;

    if(isValid){

      console.log(userInfo);
      await updateUser(userInfo);
      
      // Display update message...
      setShowAlert(true);
      setUploadFile('');
      let timeout = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
  
    }



  });


  const handleUploadFile = async (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      let extension = uploadFile.name.split('.').pop();
  
      const storageRef = ref(storage, 'branding/user/' + userInfo.uid + '/companyLogo.' + extension);
      const uploadTask = uploadBytesResumable(storageRef, uploadFile);

      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');

          var progressBar = document.getElementById('progress-company-logo').querySelector('.progress-bar');
          if(progressBar){
            progressBar.style.width = progress + '%';
            progressBar.setAttribute('aria-valuenow', progress);
          }

          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.error('Error uploading file...', error);
          reject(error);
        }, 
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL, 'for User ID: ', userInfo.uid);
            console.log('User: ', userInfo);
            userInfo.companyLogo = downloadURL;
            //firestore.collection('changeSamuiCustomers').doc(customerID).update(customer);
            //console.log('Customer updated...');
            resolve();
          });
        }
      );        
    });
}


  const handleImageDrop = (file) => {
    console.log('Handle Image Drop: ', file);
    let extension = file.name.split('.').pop();
    console.log(extension);
    setUploadFile(file);
  }

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Account", link: ""}, {title: "Personal Info", link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="accountPersonalInfo" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !userInfo && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { userInfo && 
                <>
                <div className="card-header border-bottom">
                <h1>Account Personal Info</h1>
                <h2 className="h4 text-muted mb-0">
                  Please update your personal info as needed.
                </h2>
                <p className="h6 text-muted mt-0 mb-0">Account ID: {userInfo.uid}</p>
                </div>

                <div className="card-body">
                  <form id="editPreferencesForm" method="post" onSubmit={handleSave} className="needs-validation" noValidate>
          
                    { false &&
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label form-label">Profile photo</label>

                      <div className="col-sm-9">
                        <div className="d-flex align-items-center">
                          <label className="avatar avatar-xl avatar-circle" for="avatarUploader">
                            {(userInfo && userInfo.photoURL) &&
                              <img className="avatar-img" referrerPolicy="no-referrer" src={userInfo.photoURL} alt={userInfo.name} />
                            }
                          </label>

                          <div className="d-grid d-sm-flex gap-2 ms-4">
                            <div className="form-attachment-btn btn btn-primary btn-sm">Upload photo
                              <input type="file" className="js-file-attach form-attachment-btn-label" id="avatarUploader"
                                    data-hs-file-attach-options='{
                                        "textTarget": "#avatarImg",
                                        "mode": "image",
                                        "targetAttr": "src",
                                        "resetTarget": ".js-file-attach-reset-img",
                                        "resetImg": "./assets/img/160x160/img1.jpg",
                                        "allowTypes": [".png", ".jpeg", ".jpg"]
                                    }' />
                            </div>
                            <button type="button" className="js-file-attach-reset-img btn btn-white btn-sm">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    }

                    <div className="form-floating mb-2">
                      {userInfo.authProvider === "google"
                      ? 
                      <>
                      <input type="text" id="name" name="name" className="form-control" placeholder="Full Name" aria-label="Full Name" defaultValue={userInfo.name} disabled readOnly />
                      <label htmlFor="name" className="form-label">Full Name</label>
                      <span className="form-text">Read Only (from your Google account).</span>
                      </>
                      : 
                      <>
                      <input type="text" id="name" name="name" className="form-control" placeholder="Full Name" aria-label="Full Name" defaultValue={userInfo.name} required />
                      <label htmlFor="name" className="form-label">Full Name</label>
                      <span className="form-text"><sup className="text-danger">*</sup>Required. Name as it will appear on email messages.</span>
                      </>
                      }
                    </div>

                    <div className="form-floating mb-2">
                      {userInfo.authProvider === "google"
                      ? 
                      <>
                      <input type="email" id="email" name="email" className="form-control" placeholder="Email Address" aria-label="Email Address" defaultValue={userInfo.email} disabled readOnly />
                      <label htmlFor="email" className="form-label">Email Address</label>
                      <span className="form-text">Read Only (from your Google account).</span>
                      </>
                      : 
                      <>
                      <input type="email" id="email" name="email" className="form-control" placeholder="Email Address" aria-label="Email Address" defaultValue={userInfo.email}  disabled readOnly />
                      <label htmlFor="email" className="form-label">Email Address</label>
                      <span className="form-text">Read Only. If you need to change your email address, please logout and register with a new email address.</span>
                      </>
                    }
                    </div>

                    <div className="form-floating mb-2">
                      <input type="text" id="company" name="company" className="form-control" placeholder="Company Name" aria-label="Company Name" defaultValue={userInfo.company} />
                      <label htmlFor="company" className="form-label">Company Name</label>
                      <span className="form-text">Optional. Company name as it will appear on email messages.</span>
                    </div>

                    <div className="form-floating mb-2">
                      <input type="text" id="website" name="website" className="form-control" placeholder="Website" aria-label="Website" defaultValue={userInfo.website} />
                      <label htmlFor="website" className="form-label">Website</label>
                      <span className="form-text">Optional. Website as it will appear on email messages.</span>
                    </div>

                    <div className="form-floating mb-2">
                      <input type="text" id="jobTitle" name="jobTitle" className="form-control" placeholder="Job Title" aria-label="Job Title" defaultValue={userInfo.jobTitle} />
                      <label htmlFor="jobTitle" className="form-label">Job Title</label>
                      <span className="form-text">Optional. Job title as it will appear on email messages.</span>
                    </div>

                    <div className="form-floating mb-2">
                      <input type="text" id="license" name="license" className="form-control" placeholder="License Number" aria-label="License Number" defaultValue={userInfo.license} />
                      <label htmlFor="license" className="form-label">License Number</label>
                      <span className="form-text">Optional. License number as it will appear on email messages.</span>
                    </div>


                    <div className="form-floating mb-2">
                      <MaskedInput mask={phoneMask} className="form-control" id="mobile" name="mobile" defaultValue={userInfo.mobile} placeholder="(702) 000-0000" />  
                      <label htmlFor="mobile" className="form-label">Mobile Phone</label>
                      <span className="form-text">Optional. Mobile phone number as it will appear on email messages.</span>
                    </div>

                    <div className="form-floating mb-2">
                      <MaskedInput mask={phoneMask} className="form-control" id="office" name="office" defaultValue={userInfo.office} placeholder="(702) 000-0000" />  
                      <label htmlFor="office" className="form-label">Office Phone</label>
                      <span className="form-text">Optional. Office phone number as it will appear on email messages.</span>
                    </div>

                    <div className="form-floating mb-2">
                      <textarea rows="4" id="officeAddress" name="officeAddress" className="form-control" placeholder="Office Address" aria-label="Office Address" defaultValue={userInfo.officeAddress} />
                      <label htmlFor="officeAddress" className="form-label">Office Address</label>
                      <span className="form-text">Optional. Office address as it will appear on email messages.</span>
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button className="btn btn-primary"><i className="bi bi-save me-2"></i>Save</button>
                    </div>
                  </form>
                </div>


                
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Account personal info updated...</p>                    
                </div>
            </div>
          </div>
      )}
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default AccountPersonalInfo;