import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, updateUser } from "./firebaseAuth";
import MaskedInput from "react-text-mask";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { Rings } from "react-loader-spinner";
import "./AccountPreferences.css";
import { addDays, addBusinessDays, subDays, subBusinessDays } from "date-fns";
import {  Timestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getTransactionTemplates, addTransaction, titleCase, currencyMask, dateMask, dateMaskPipe } from "./firebaseData";
import { FileUploader } from "react-drag-drop-files";



function AccountPreferences(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [transactionTemplates, setTransactionTemplates] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [uploadFile, setUploadFile] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    document.title ="Transaction Minder: Account Preferences";

    async function fetchData(){
      const userDoc = await getUser(user);
      const versionInfo = await fetchVersionInfo();
      const transactionTemplates = await getTransactionTemplates();
      setUserInfo(userDoc);
      setVersionID(versionInfo);
      setTransactionTemplates(transactionTemplates);
    }
    fetchData();
  }, [user, loading]);

  const handleSave = (async (event) =>{
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    userInfo.defaultTemplate = formJson.defaultTemplate;

    if(uploadFile){
      await handleUploadFile();
    }

    console.log(userInfo);
    await updateUser(userInfo);
    
    // Display update message...
    setShowAlert(true);
    setUploadFile('');
    let timeout = setTimeout(() => {
      setShowAlert(false);
    }, 5000);

  });


  const handleUploadFile = async (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      let extension = uploadFile.name.split('.').pop();
  
      const storageRef = ref(storage, 'branding/user/' + userInfo.uid + '/companyLogo.' + extension);
      const uploadTask = uploadBytesResumable(storageRef, uploadFile);

      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');

          var progressBar = document.getElementById('progress-company-logo').querySelector('.progress-bar');
          if(progressBar){
            progressBar.style.width = progress + '%';
            progressBar.setAttribute('aria-valuenow', progress);
          }

          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.error('Error uploading file...', error);
          reject(error);
        }, 
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL, 'for User ID: ', userInfo.uid);
            console.log('User: ', userInfo);
            userInfo.companyLogo = downloadURL;
            //firestore.collection('changeSamuiCustomers').doc(customerID).update(customer);
            //console.log('Customer updated...');
            resolve();
          });
        }
      );        
    });
}


  const handleImageDrop = (file) => {
    console.log('Handle Image Drop: ', file);
    let extension = file.name.split('.').pop();
    console.log(extension);
    setUploadFile(file);
  }

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: "Account", link: ""}, {title: "Preferences", link: ''}]} />

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav="accountPreferences" />
            </div> 
            <div className="col-lg-9">
              <div className="card">

              { !transactionTemplates && 
                <div className="card-header border-bottom">
                  <div className="text-center">
                    <h1>Loading...</h1>
                      <Rings
                        height="180"
                        width="180"
                        color="#0000ff"
                        radius="6"
                        wrapperStyle={{display:"block"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                  </div>
                </div>
              }

              { transactionTemplates && 
                <>
                <div className="card-header border-bottom">
                <h1>Account Preferences</h1>
                <h2 className="h6 text-muted">
                  Please update your preferences as needed.<br />
                </h2>
                </div>

                <div className="card-body">
                  <form id="editPreferencesForm" method="post" onSubmit={handleSave} className="needs-validation" noValidate>
          
                    <div className="row row-cols-1">
                      
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="defaultTemplate" className="form-label">Default Template</label>
                          <select id="defaultTemplate" name="defaultTemplate" className="form-select" aria-label="Default Template" defaultValue={userInfo.defaultTemplate}>
                            { transactionTemplates.map(template => ( 
                            <option key={template.id} value={template.id}>{template.title}</option>
                            ))}
                          </select>
                          <span className="form-text">Please select the default template for your transactions.</span>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="companyLogo" className="form-label">Company Logo</label>

                          { userInfo.companyLogo && 
                          <div id="imageDisplayArea" className="my-3 border rounded p-3">
                              <img style={{ maxHeight: 200, maxWidth: 200 }} src={userInfo.companyLogo}  />
                          </div>
                          }

                          {uploadFile &&
                          <div id="imagePreviewArea" className="my-3 border rounded p-3">
                              <img style={{ maxHeight: 200, maxWidth: 200 }} src={URL.createObjectURL(uploadFile)} />
                              <div id='progress-company-logo' className="progress my-1" style={{ minHeight: '20px'}}>
                                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width: '0%'}}></div>
                              </div>
                          </div>
                          }

                          <FileUploader handleChange={handleImageDrop} name="file" types={["JPG", "JPEG", "PNG"]} multiple={false} />
                        </div>  
                      </div>                    
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button className="btn btn-primary"><i className="bi bi-save me-2"></i>Save</button>
                    </div>
                  </form>
                </div>
                </>
              }                
              </div>
            </div> 
          </div>
        </div>
      </main>
      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Account preferences updated...</p>                    
                </div>
            </div>
          </div>
      )}
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default AccountPreferences;