import React from "react";
import { validateEmail } from "./firebaseData";
import "./TransactionSendEmail.css";

function TransactionSendEmail(props) {

  const handleSaveTransactionSendEmail = (async (event) => { 
    event.preventDefault();

    console.log('Transaction Send Email Event');

    const form = document.getElementById('editTransactionSendEmailForm');
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    //Validate that value is a CSV of email addresses...
    let isValid = true;
    let emailTransactionListElement = document.getElementById("emailTransactionList");  
    let emailList = [];

    if(formJson.emailTransactionList){

      emailList = formJson.emailTransactionList.split(',').map(function(item) {
        return item.trim();
      });
      console.log(emailList);

      if(emailList[0] === ''){
        emailList = [];
      } else {

        for(let i=0; i < emailList.length; i++) {
          let emailAddress = emailList[i];
          console.log("Testing: ", emailAddress);
          if(!validateEmail(emailAddress)){
            isValid = false;
            break;
          }
        };  

      }
    } else {
      isValid = false;
    }

    if(isValid){
      //Manually hide modal...
      document.getElementById('modalTransactionSendEmail').classList.remove('show');
      document.body.classList.remove('modal-open');
      document.getElementsByClassName('modal-backdrop')[0].remove();
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');

      emailTransactionListElement.value = "";
      emailTransactionListElement.classList.remove('is-invalid');
      emailTransactionListElement.classList.remove('is-valid');
      document.getElementById("emailTransactionListIsValid").classList.remove('text-danger');
      document.getElementById("emailTransactionListIsValid").classList.remove('fw-bold');

      let emailTransactionMessageElement = document.getElementById("emailTransactionMessage");
      emailTransactionMessageElement.value = "";

      let results = {
        emailTransactionID: formJson.emailTransactionID,
        emailList: emailList,
        emailTransactionMessage: formJson.emailTransactionMessage
      }

      props.callback(results);
    } else {
      emailTransactionListElement.classList.remove('is-valid');
      emailTransactionListElement.classList.add('is-invalid');
      document.getElementById("emailTransactionListIsValid").classList.add('text-danger');
      document.getElementById("emailTransactionListIsValid").classList.add('fw-bold');
      console.log('List does not contain valid email addresses...');
    }

  });


  return (
    <>
      <div className="modal fade" id="modalTransactionSendEmail" tabIndex="-1" aria-labelledby="modalTransactionSendEmailLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form id="editTransactionSendEmailForm" method="post" onSubmit={handleSaveTransactionSendEmail} className="needs-validation" noValidate>
              <div className="modal-header">
                <h3 className="modal-title mt-3" id="modalTransactionSendEmailLabel"><i className="bi bi-envelope  me-2"></i>Send One Time Email For This Transaction</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
              <p className="mb-5">Please enter a comma separated list of <span id="emailTransactionListIsValid">valid</span> email addresses for people you want to a one time email copy of this transaction status along with whatever message you would like to share.</p>
              <input type="hidden" id="emailTransactionID" name="emailTransactionID" />
                <div className="mb-3">
                  <label htmlFor="emao=ilTransactionList" className="form-label">Email Contact List</label>
                  <input type="text" id="emailTransactionList" name="emailTransactionList" className="form-control" placeholder="Comma separated list of email addresses" aria-label="Email Transaction Contact List" />
                </div>

                <div className="mb-3">
                  <label htmlFor="emailTransactionMessage" className="form-label">Message</label>
                  <textarea className="form-control" id="emailTransactionMessage" name="emailTransactionMessage" rows="3"></textarea>
                </div>                
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveTransactionSendEmail} >Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>    
    </>
  );
}

export default TransactionSendEmail;
