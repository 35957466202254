import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebaseAuth";
import { getTransactionTemplates } from "./firebaseData";

import logo from "./logo.800x200.png";
import "./PageHeader.css";

function PageHeader(props) {
  const [user, loading, error] = useAuthState(auth);
  const [templates, setTemplates] = useState([]);
  const addTransactionModalRef = useRef();
  const navigate = useNavigate();

  async function fetchData(){
    //Get data
    setTemplates(await getTransactionTemplates());
  }

  useEffect(() => {
    if (loading) return;
    //if (!user) return navigate("/login");
    if(user){
      console.log('User UID: ', user.uid);
      fetchData();  
    }

  }, [user, loading]);


  const handleAddTransaction = () => {
    console.log("Add transaction clicked..."); 

    const addTransactionModal = addTransactionModalRef.current;
    const bsModal = new bootstrap.Modal(addTransactionModal);
    bsModal.show();    

  }

  const handleAddTransactionWithSelectedTemplate = () => {
    let selectedTemplate = document.getElementById("template").value;
    console.log("Continue with selected template clicked...", selectedTemplate); 

    const addTransactionModal = addTransactionModalRef.current;
    const bsModal = bootstrap.Modal.getInstance(addTransactionModal);
    bsModal.hide();    

    return navigate("/transaction/add/" + selectedTemplate);
  }

  return (
    <>
      <header id="header" className="navbar navbar-expand-lg navbar-end navbar-light bg-white">
        <div className="container navbar-topbar">
          <nav className="js-mega-menu navbar-nav-wrap">

            <div id="topbarNavDropdown" className="navbar-nav-wrap-collapse collapse navbar-collapse navbar-topbar-collapse">
            </div>
          </nav>
        </div>

        <div className="container">
            <a className="navbar-brand" href="/" aria-label="Transaction Minder">
              <img className="navbar-brand-logo" src={logo} alt="Logo" />
            </a>
            { user &&
            <>
            <div className="float-end">
              <a className="btn btn-primary btn-transition me-2" href="/dashboard" ><i className="bi bi-speedometer2 me-2"></i>Dashboard</a>
              <a id="headerAddTransactionButton" className="btn btn-success btn-transition" onClick={handleAddTransaction} ><i className="bi bi-plus-circle me-2"></i>Add Transaction</a>
            </div>
            </>
            }
        </div>
      </header>

      <div className="modal modal-xl fade" id="addTransaction" tabIndex="-1" aria-labelledby="addTransactionLabel" aria-hidden="true" ref={addTransactionModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addTransactionLabel">Add Transaction</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black"> 
              <form id="addTtransactionForm">                 
                <div className="mb-3">
                  <label htmlFor="template" className="form-label fw-bold">Selecte Teamplate</label>
                  <select className="form-select" aria-label="Template"  id="template" name="template">
                    {templates && templates.length > 0 && templates.map(template => (
                      template.published === true &&
                      <option key={template.id} value={template.id}>{template.title}</option>
                    ))}
                  </select>
                </div>

              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleAddTransactionWithSelectedTemplate}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default PageHeader;
