import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { auth } from "./firebaseAuth";
//import { useAuthState } from "react-firebase-hooks/auth";
//import { useNavigate } from "react-router-dom";
//import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";


import { logout } from "./firebaseAuth";
import ScrollToTop from "./ScrollToTop";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import TransactionAdd from "./TransactionAdd";
import TransactionEdit from "./TransactionEdit";
import AccountPreferences from "./AccountPreferences";
import AccountPersonalInfo from "./AccountPersonalInfo";
import Teams from "./Teams";
import BillingPayments from "./BillingPayments";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import Team from "./Team";
import HomePage from "./HomePage";
import TeamEdit from "./TeamEdit";
import Template from "./Template";
import Templates from "./Templates";
import TemplateEdit from "./TemplateEdit";


function RequireAuth({ children }) {
  return auth ? children : <Navigate to="/login" replace />;
}

function App() {

  return (
    <div className="app">
      <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/logout" action={logout} element={<Login />} />
            <Route exact path="/dashboard" element={<RequireAuth><Dashboard list="personal" status="open"/></RequireAuth>} />
            <Route exact path="/transaction/add/:templateID" element={<RequireAuth><TransactionAdd action="add" /></RequireAuth>} />
            <Route path="/transaction/edit/:transactionID" element={<RequireAuth><TransactionEdit action="edit" /></RequireAuth>} />
            <Route exact path="/closedTransactions" element={<RequireAuth><Dashboard list="personal" status="closed" /></RequireAuth>} />
            <Route exact path="/teamTransactions" element={<RequireAuth><Dashboard list="team"/></RequireAuth>} />
            <Route exact path="/teams" element={<RequireAuth><Teams /></RequireAuth>} />
            <Route path="/team/:teamID" element={<RequireAuth><Team /></RequireAuth>} />
            <Route path="/team/edit/:teamID" element={<RequireAuth><TeamEdit /></RequireAuth>} />

            <Route exact path="/templates" element={<RequireAuth><Templates /></RequireAuth>} />
            <Route path="/template/:templateID" element={<RequireAuth><Template /></RequireAuth>} />
            <Route path="/template/edit/:templateID" element={<RequireAuth><TemplateEdit action="edit" /></RequireAuth>} />
            <Route path="/template/add/:templateID" element={<RequireAuth><TemplateEdit action="add" /></RequireAuth>} />

            <Route exact path="/account/preferences" element={<RequireAuth><AccountPreferences /></RequireAuth>} />
            <Route exact path="/account/personalInfo" element={<RequireAuth><AccountPersonalInfo /></RequireAuth>} />
            <Route exact path="/billing/payments" element={<RequireAuth><BillingPayments /></RequireAuth>} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;